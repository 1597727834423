// frontend/src/components/Charts/TinyAreaChart.js

import React from 'react';
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TinyAreaChart = ({ data, color = '#8884d8' }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const chartHeight = isSmallScreen ? 80 : 120; // Adjust heights as needed

    return (
        <ResponsiveContainer width="100%" height={chartHeight}>
            <AreaChart data={data}>
                <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                <Tooltip
                    contentStyle={{ backgroundColor: '#fff', border: 'none', fontSize: '12px' }}
                    labelFormatter={(label) => `Date: ${label}`}
                    formatter={(value) => [`${value}`, 'Orders']}
                />
                <XAxis 
                    dataKey="date" 
                    hide={isSmallScreen} // Hide labels on small screens to save space
                    fontSize={10} 
                    tick={{ fill: '#555' }} 
                />
                <YAxis hide={true} />
                <Area
                    type="monotone"
                    dataKey="value"
                    stroke={color}
                    fill={color}
                    strokeWidth={2}
                    dot={{ r: 2 }}
                    isAnimationActive={true}
                    animationDuration={500}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default TinyAreaChart;
