// frontend/src/theme.js

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import '@fontsource/roboto';

// Create a base theme
let theme = createTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h1: {
            fontSize: '2.5rem', // 40px
            fontWeight: 700,
            fontStyle: 'normal',
            // Responsive adjustments
            '@media (max-width:600px)': {
                fontSize: '2rem', // 32px
            },
        },
        h2: {
            fontSize: '2rem', // 32px
            fontWeight: 700,
            fontStyle: 'normal',
            '@media (max-width:600px)': {
                fontSize: '1.75rem', // 28px
            },
        },
        h3: {
            fontSize: '1.75rem', // 28px
            fontWeight: 600,
            fontStyle: 'normal',
            '@media (max-width:600px)': {
                fontSize: '1.5rem', // 24px
            },
        },
        h4: {
            fontSize: '1.5rem', // 24px
            fontWeight: 600,
            fontStyle: 'normal',
            '@media (max-width:600px)': {
                fontSize: '1.25rem', // 20px
            },
        },
        h5: {
            fontSize: '1.25rem', // 20px
            fontWeight: 600,
            fontStyle: 'normal',
            '@media (max-width:600px)': {
                fontSize: '1rem', // 16px
            },
        },
        h6: {
            fontSize: '1rem', // 16px
            fontWeight: 600,
            fontStyle: 'normal',
            '@media (max-width:600px)': {
                fontSize: '0.875rem', // 14px
            },
        },
        body1: {
            fontSize: '1rem', // 16px
            fontWeight: 500,
            fontStyle: 'normal',
            '@media (max-width:600px)': {
                fontSize: '0.875rem', // 14px
            },
        },
        body2: {
            fontSize: '0.875rem', // 14px
            fontWeight: 500,
            fontStyle: 'normal',
            '@media (max-width:600px)': {
                fontSize: '0.75rem', // 12px
            },
        },
        // If you want to use the <p> tag with a specific variant, you can map it to body1 or create a custom variant.
        // Here's how to map it to body1 globally:
        p: {
            variant: 'body1',
        },
        allVariants: {
            color: '#00000099', // Global text color
        },
    },
    palette: {
        primary: {
            main: '#1976d2', // Your desired primary color
            dark: '#115293', // Darker shade for hover effect
        },
        secondary: {
            main: '#dc004e',
        },
        text: {
            primary: '#1C252E', // Set primary text color globally
            secondary: '#637381',
            disabled: '#919EAB',
        },
        background: {
            default: '#f5f5f5', // Set the default background color if needed
            paper: '#fff', // Set the background color for paper components
        },
        dispatch: {
            green: '#20c997',
            orange: '#ff9800',
            red: '#dc3545',
        },
    },
    components: {
        // Removed MuiTypography color override
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                    borderSpacing: '0 8px', // Adds space between rows
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fafafa', // Light grey for table headers
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: '#f1f1f1', // Hover effect on rows
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '12px 16px',
                    borderBottom: 'none', // Remove default border
                },
                head: {
                    fontWeight: 'bold',
                    color: '#333',
                },
                body: {
                    color: '#555',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    borderTop: `1px solid ${'#e0e0e0'}`, // Light border on top
                },
            },
        },
    },
});

// Enhance the theme with responsive font sizes
theme = responsiveFontSizes(theme, {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    factor: 2, // Adjust the scaling factor as needed
});

export default theme;
