// frontend/src/components/Cards/SummaryCardWithPieChart.js

import React from 'react';
import { Paper, Box, Typography, Tooltip } from '@mui/material';
import PieChartComponent from '../Charts/PieChart';
import { useTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SummaryCardWithPieChart = ({
    icon: Icon,
    label,
    percentage,
    color,
    tooltip,
    sx, // Accept sx prop for custom styling
}) => {
    const theme = useTheme();
    return (
        <Paper
            elevation={3}
            sx={{
                p: 3,
                borderRadius: 2,
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%', // Allow card to fill parent height
                ...sx, // Apply custom styles if any
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 56,
                        width: 56,
                        backgroundColor: color,
                        color: '#fff',
                        borderRadius: '50%',
                        mr: 2,
                    }}
                >
                    <Icon fontSize="large" />
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                    {label}
                </Typography>
                {tooltip && (
                    <Tooltip title={tooltip} arrow>
                        <Box sx={{ ml: 1 }}>
                            <InfoOutlinedIcon fontSize="small" color="action" />
                        </Box>
                    </Tooltip>
                )}
            </Box>
            <Typography variant="h2" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                {percentage}%
            </Typography>
            <Box sx={{ width: '250px', height: '250px', mt: 2 }}>
                <PieChartComponent percentage={percentage} />
            </Box>
            {tooltip && (
                <Tooltip title={tooltip} arrow>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1, textAlign: 'center' }}>
                        {tooltip}
                    </Typography>
                </Tooltip>
            )}
        </Paper>
    );
};

export default SummaryCardWithPieChart;
