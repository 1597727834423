// frontend/src/components/EstimateDetailModal.js

import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Grid,
    IconButton,
    Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
};

const EstimateDetailModal = ({ estimate, open, onClose }) => {
    if (!estimate) return null;

    const estimateDetails = [
        { label: 'Estimate Number', value: estimate.EstRefNum },
        { label: 'Customer Reference', value: estimate.CustomerRef },
        { label: 'Customer Name', value: estimate.CustomerName },
        { label: 'Create Date', value: new Date(estimate.CreatedDateTime).toLocaleString() },
        { label: 'Job Type', value: estimate.JobTypeDesc },
        { label: 'Total Amount', value: `$${estimate.TotalTotal.toFixed(2)}` },
        { label: 'Status', value: estimate.StatusRef ? <Chip label={estimate.StatusRef} color="info" /> : 'N/A' },
        // Add more fields as necessary
    ];

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="estimate-detail-modal-title"
            aria-describedby="estimate-detail-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography id="estimate-detail-modal-title" variant="h6" component="h2">
                        Estimate Details
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={2}>
                    {estimateDetails.map((detail, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    {detail.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1">
                                    {detail.value}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                {/* Add more sections as needed */}
            </Box>
        </Modal>
    );
};

export default EstimateDetailModal;
