// frontend/src/components/Charts/PieChart.js

import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme, useMediaQuery } from '@mui/material';

const PieChartComponent = ({ percentage }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const chartSize = isSmallScreen ? 150 : 250; // Adjust size based on screen

    const data = [
        { name: 'Completed', value: percentage },
        { name: 'Remaining', value: 100 - percentage },
    ];

    const COLORS = [
        percentage >= 98 ? theme.palette.dispatch.green : percentage >= 95 ? theme.palette.dispatch.orange : theme.palette.dispatch.red,
        '#e0e0e0',
    ];

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    data={data}
                    innerRadius={isSmallScreen ? 40 : 60}
                    outerRadius={isSmallScreen ? 70 : 90}
                    startAngle={90}
                    endAngle={-270}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip
                    formatter={(value, name) => [`${value}%`, name]}
                    contentStyle={{ backgroundColor: '#fff', border: 'none', fontSize: '12px' }}
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PieChartComponent;
