// frontend/src/components/IssueDialog.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem } from '@mui/material';
import { addOrderIssue, getIssueStatuses } from '../services/api';
import { useSnackbar } from '../contexts/SnackbarContext';

const IssueDialog = ({ open, onClose, order, onIssueAdded }) => {
    const { showSnackbar } = useSnackbar();
    const [statusId, setStatusId] = useState('');
    const [comments, setComments] = useState('');
    const [issueStatuses, setIssueStatuses] = useState([]);

    useEffect(() => {
        const fetchIssueStatuses = async () => {
            try {
                const data = await getIssueStatuses();
                if (data.success && Array.isArray(data.statuses)) {
                    setIssueStatuses(data.statuses);
                } else {
                    console.error('Unexpected response structure:', data);
                    showSnackbar('Failed to fetch issue statuses.', 'error');
                }
            } catch (error) {
                console.error('Error fetching issue statuses:', error);
                showSnackbar('Failed to fetch issue statuses.', 'error');
            }
        };
        fetchIssueStatuses();
    }, [showSnackbar]);

    useEffect(() => {
        if (issueStatuses.length > 0) {
            if (order.issueStatus) {
                const status = issueStatuses.find(s => s.statusName === order.issueStatus);
                if (status) {
                    setStatusId(status.statusId);
                }
                setComments(order.comments || '');
            } else {
                setStatusId('');
                setComments('');
            }
        }
    }, [order, issueStatuses]);

    const handleSubmit = async () => {
        try {
            await addOrderIssue(order.orderId, { statusId, comments });
            onClose();
            onIssueAdded();
            showSnackbar('Issue saved successfully', 'success');
        } catch (error) {
            console.error('Error adding/updating issue:', error);
            showSnackbar('Error adding/updating issue', 'error');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Add Issue to Order {order.orderNumber}</DialogTitle>
            <DialogContent>
                <TextField
                    select
                    label="Issue Status"
                    value={statusId}
                    onChange={(e) => setStatusId(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    {issueStatuses.map((status) => (
                        <MenuItem key={status.statusId} value={status.statusId}>
                            {status.statusName}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!statusId}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default IssueDialog;
