// frontend/src/components/Logs.js

import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    CircularProgress,
} from '@mui/material';
import Layout from './Layout';
import { useSnackbar } from '../contexts/SnackbarContext';
import axios from 'axios';

const Logs = () => {
    const { showSnackbar } = useSnackbar();
    const [availableLogs, setAvailableLogs] = useState([]);
    const [selectedLog, setSelectedLog] = useState('');
    const [logEntries, setLogEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        fetchAvailableLogs();
    }, []);

    useEffect(() => {
        if (selectedLog) {
            fetchLogEntries(selectedLog);
        } else {
            setLogEntries([]);
        }
    }, [selectedLog]);

    useEffect(() => {
        handleSearch();
    }, [searchTerm, logEntries]);

    const fetchAvailableLogs = async () => {
        try {
            const response = await axios.get('/api/logs');
            if (response.data.success) {
                setAvailableLogs(response.data.logs);
            } else {
                showSnackbar('Error fetching available logs.', 'error');
            }
        } catch (error) {
            console.error('Error fetching available logs:', error);
            showSnackbar('Error fetching available logs.', 'error');
        }
    };

    const fetchLogEntries = async (log, page = 1, limit = 10000) => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/logs/${log.app}/${log.filename}`, {
                params: { page, limit }
            });
            if (response.data.success) {
                setLogEntries(response.data.logs);
                // Optionally, set total logs and update pagination controls
            } else {
                showSnackbar('Error fetching log entries.', 'error');
            }
        } catch (error) {
            console.error('Error fetching log entries:', error);
            showSnackbar('Error fetching log entries.', 'error');
        }
        setLoading(false);
    };

    const handleSearch = () => {
        if (searchTerm.trim() === '') {
            setFilteredLogs(logEntries);
        } else {
            const lowerSearch = searchTerm.toLowerCase();
            const filtered = logEntries.filter(entry => {
                // Check all string fields for the search term
                return Object.values(entry).some(value => 
                    typeof value === 'string' && value.toLowerCase().includes(lowerSearch)
                );
            });
            setFilteredLogs(filtered);
            setPage(0); // Reset to first page on new search
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    API Webhook Logs
                </Typography>

                {/* Select Log File Dropdown */}
                <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel id="log-select-label">Select Log File</InputLabel>
                        <Select
                            labelId="log-select-label"
                            id="log-select"
                            value={selectedLog}
                            label="Select Log File"
                            onChange={(e) => setSelectedLog(e.target.value)}
                        >
                            {availableLogs.map((log, index) => (
                                <MenuItem key={`${log.app}-${log.filename}-${index}`} value={log}>
                                    {`${log.app} - ${log.filename}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Search Field */}
                    <FormControl sx={{ flexGrow: 1, minWidth: 200 }}>
                        <TextField
                            label="Search Logs"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            fullWidth
                        />
                    </FormControl>
                </Box>

                {/* Log Entries Table */}
                <Paper elevation={3} sx={{ p: 2 }}>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Typography variant="subtitle1" sx={{ mb: 2 }}>
                                {selectedLog ? `Displaying logs for: ${selectedLog.app} - ${selectedLog.filename}` : 'Please select a log file to view entries.'}
                            </Typography>
                            {filteredLogs.length > 0 ? (
                                <>
                                    <TableContainer sx={{ maxHeight: 600 }}>
                                        <Table stickyHeader aria-label="logs table">
                                            <TableHead>
                                                <TableRow>
                                                    {Object.keys(filteredLogs[0]).map((key) => (
                                                        <TableCell key={key} align="left">
                                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredLogs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((entry, index) => (
                                                    <TableRow key={index}>
                                                        {Object.values(entry).map((value, idx) => (
                                                            <TableCell key={idx} align="left">
                                                                {typeof value === 'object' ? JSON.stringify(value) : value}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        component="div"
                                        count={filteredLogs.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            ) : (
                                selectedLog && (
                                    <Typography variant="body1" color="textSecondary">
                                        No log entries found for the given search term.
                                    </Typography>
                                )
                            )}
                        </>
                    )}
                </Paper>
            </Box>
        </Layout>
    );
};

export default Logs;
