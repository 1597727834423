// frontend/src/components/AccessDenied.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';

const AccessDenied = () => {
    const navigate = useNavigate();

    return (
        <Layout>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                padding: '5px',
            }}
        >
            <Typography variant="h3" gutterBottom>
                Access Denied
            </Typography>
            <Typography variant="h6" gutterBottom>
                You do not have permission to view this page.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/dashboard')}
                sx={{ marginTop: '20px' }}
            >
                Return to Dashboard
            </Button>
        </Box>
        </Layout>
    );
};

export default AccessDenied;
