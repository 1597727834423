// frontend/src/components/ClientTable.js

import React from 'react';
import { 
    Box, 
    TableContainer, 
    Paper, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody, 
    IconButton, 
    Typography, 
    Button, 
    LinearProgress, 
    Tooltip, 
    Chip 
} from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material/styles';
import { getColorByPercentage, getDispatchColor } from '../utils/colorUtils'; // Adjust path accordingly

const ClientTable = ({ clients, onDispatchTodayClick, onCutoffTimeClick }) => {
    const theme = useTheme();
    const dispatchPalette = theme.palette.dispatch;

    return (
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Tooltip title="Cutoff Times">
                                <AlarmIcon />
                            </Tooltip>
                        </TableCell>
                        <TableCell>Client Name</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                            Total New Orders (Today)
                        </TableCell>
                        <TableCell>Required Dispatch Today</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                            Dispatched Today
                        </TableCell>
                        <TableCell>Cancelled Orders Today</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                            Fulfilment Accuracy Rate
                        </TableCell>
                        <TableCell>Progress (%)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients.map((client) => {
                        // Calculate displayProgress: 100 if both requiredDispatchToday and dispatchedOrders are 0
                        const displayProgress = 
                            (client.requiredDispatchToday === 0 && client.dispatchedOrders === 0) 
                                ? 100 
                                : (client.progressPercentage > 100 ? 100 : client.progressPercentage || 0);

                        const dynamicColor = getColorByPercentage(displayProgress, dispatchPalette);

                        return (
                            <TableRow key={client.clientName}>
                                {/* Cutoff Times Icon/Button */}
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => onCutoffTimeClick(e, client)}
                                        aria-label={`View cutoff times for ${client.clientName}`}
                                    >
                                        <AlarmIcon color="action" />
                                    </IconButton>
                                </TableCell>
                                <TableCell>{client.clientName}</TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                    {client.totalNewOrders || 0}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => onDispatchTodayClick(client.clientName)}
                                        sx={{
                                            textTransform: 'none',
                                            fontWeight: 'bold',
                                            padding: 0,
                                            minWidth: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            '&:hover .info-icon': {
                                                color: getDispatchColor(client.requiredDispatchToday, dispatchPalette),
                                            },
                                        }}
                                        aria-label={`View Required Dispatch Today Orders for ${client.clientName}`}
                                    >
                                        <Typography 
                                                sx={{ 
                                                color: getDispatchColor(client.requiredDispatchToday, dispatchPalette), 
                                                fontWeight: 'bold' 
                                            }}
                                        >
                                            {client.requiredDispatchToday || 0}
                                        </Typography>
                                        <InfoOutlinedIcon
                                            fontSize="small"
                                            className="info-icon"
                                            sx={{ color: 'text.secondary' }}
                                        />
                                    </Button>
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                    <Typography sx={{ color: dispatchPalette.green, fontWeight: 'bold' }}>
                                        {client.dispatchedOrders || 0}
                                    </Typography>
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        color: client.cancelledOrdersToday > 0 
                                            ? dispatchPalette.red 
                                            : dispatchPalette.green, 
                                        fontWeight: 'bold' 
                                    }}
                                >
                                    {client.cancelledOrdersToday || 0}
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: client.fulfilmentAccuracyRate >= 90
                                                ? 'success.main'
                                                : client.fulfilmentAccuracyRate >= 75
                                                    ? 'warning.main'
                                                    : 'error.main',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {client.fulfilmentAccuracyRate
                                            ? `${client.fulfilmentAccuracyRate.toFixed(2)}%`
                                            : '0.00%'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Typography 
                                            variant="body2" 
                                            sx={{ 
                                                fontWeight: 'bold', 
                                                mr: 1, 
                                                fontSize: { xs: '0.75rem', sm: '0.875rem' } 
                                            }}
                                        >
                                            {displayProgress}%
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={displayProgress}
                                            sx={{
                                                flexGrow: 1,
                                                height: 8,
                                                borderRadius: 5,
                                                [`& .MuiLinearProgress-bar`]: {
                                                    backgroundColor: dynamicColor,
                                                    transition: 'background-color 0.3s ease',
                                                },
                                                transition: 'background-color 0.3s ease',
                                            }}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
                }
    export default ClientTable;
