// frontend/src/components/TharsternJobs.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    CircularProgress,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Layout from './Layout';
import CustomTable from './CustomTable';
import DateRangeSelector from './DateRangeSelector';
import { getTharsternJobs } from '../services/api';
import { useSnackbar } from '../contexts/SnackbarContext';
import JobDetailModal from './JobDetailModal';
import dayjs from 'dayjs'; // Ensure dayjs is imported

const TharsternJobs = () => {
    // Calculate initial dates for 'last30days'
    const today = dayjs();
    const initialStartDate = today.subtract(29, 'day').format('YYYY-MM-DD'); // 30 days including today
    const initialEndDate = today.format('YYYY-MM-DD');

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('last30days'); // Default date range
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [filters, setFilters] = useState({
        jobNumber: '',
        customerRef: '',
        customerName: '',
        startDate: initialStartDate,
        endDate: initialEndDate,
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedJob, setSelectedJob] = useState(null);
    const [total, setTotal] = useState(0); // Add total state
    const { showSnackbar } = useSnackbar();

    const fetchJobs = async () => {
        setLoading(true);
        try {
            console.log('Fetching Jobs with Filters:', {
                jobNumber: filters.jobNumber,
                customerRef: filters.customerRef,
                customerName: filters.customerName,
                createDateFrom: filters.startDate,
                createDateTo: filters.endDate,
                page: page + 1,
                limit: rowsPerPage,
            });
            const response = await getTharsternJobs({
                jobNumber: filters.jobNumber,
                customerRef: filters.customerRef,
                customerName: filters.customerName,
                createDateFrom: filters.startDate,
                createDateTo: filters.endDate,
                page: page + 1, // API expects 1-based page index
                limit: rowsPerPage,
            });
            if (response.success) {
                setJobs(response.data);
                setTotal(response.total); // Set total from backend
            } else {
                showSnackbar('Failed to fetch jobs.', 'error');
            }
        } catch (error) {
            showSnackbar('Error fetching jobs.', 'error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, page, rowsPerPage]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSearch = () => {
        setFilters(prev => ({
            ...prev,
            startDate: startDate,
            endDate: endDate,
        }));
        setPage(0); // Reset to first page on search
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (job) => { // Receive entire job object
        setSelectedJob(job);
    };

    const handleModalClose = () => {
        setSelectedJob(null);
    };

    return (
        <Layout>
            <Box sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>
                    Tharstern Jobs
                </Typography>

                {/* Filters */}
                <Paper sx={{ p: 2, mb: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label="Job Number"
                                name="jobNumber"
                                value={filters.jobNumber}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label="Customer Reference"
                                name="customerRef"
                                value={filters.customerRef}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label="Customer Name"
                                name="customerName"
                                value={filters.customerName}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Search />}
                                onClick={handleSearch}
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <DateRangeSelector
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                {/* Jobs Table */}
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : jobs.length > 0 ? (
                    <CustomTable
                        columns={[
                            { id: 'JobNo', label: 'Job Number', sortable: true },
                            { id: 'InvoiceCustomerName', label: 'Customer Name', sortable: true },
                            { id: 'CreateDateTime', label: 'Create Date', sortable: true },
                            { id: 'JobTypeDesc', label: 'Job Type', sortable: true },
                            { id: 'UnitPrice', label: 'Unit Price', sortable: true },
                            { id: 'TotalAmount', label: 'Total Amount', sortable: true },
                            // Add more columns as needed based on your job data
                        ]}
                        data={jobs}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        onRowClick={handleRowClick}
                        total={total} // Pass total to CustomTable
                        rowId="JobNo" // Set rowId to match your data's unique identifier
                    />
                ) : (
                    <Typography variant="h6" align="center" sx={{ mt: 4 }}>
                        No records found.
                    </Typography>
                )}

                {/* Job Detail Modal */}
                {selectedJob && (
                    <JobDetailModal job={selectedJob} open={Boolean(selectedJob)} onClose={handleModalClose} />
                )}
            </Box>
        </Layout>
    )
}
export default TharsternJobs;
