// frontend/src/components/FulfillmentReport.js

import React, { useEffect, useState, useMemo } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Grid,
    useMediaQuery,
    useTheme,
    Paper,
} from '@mui/material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line,
} from 'recharts';
import { getFulfillmentCounts } from '../services/api';
import { useSnackbar } from '../contexts/SnackbarContext';
import moment from 'moment';
import CustomTable from './CustomTable'; // Import the CustomTable component

const fulfillmentCategories = ['0 Days', '1 Day', '2 Days', '3 Days', '4+ Days'];
const colors = ['#197DC2', '#20c997', '#dc3545', '#ffc107', '#6f42c1', '#007bff']; // Add more colors as needed

const FulfillmentReport = ({ granularity, startDate, endDate }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showSnackbar } = useSnackbar();

    const fetchReportData = async () => {
        setLoading(true);
        try {
            const data = await getFulfillmentCounts(granularity, startDate, endDate);

            // Process data to aggregate fulfillment categories per client and include AvgFulfillmentTime
            const clientMap = {};

            data.forEach(item => {
                const client = item.clientName;
                if (!clientMap[client]) {
                    clientMap[client] = {
                        clientName: client,
                        '0 Days': 0,
                        '1 Day': 0,
                        '2 Days': 0,
                        '3 Days': 0,
                        '4+ Days': 0,
                        AvgFulfillmentTime: item.AvgFulfillmentTime,
                    };
                }
                clientMap[client][item.FulfillmentCategory] = item.OrderCount;
            });

            const processedData = Object.values(clientMap);

            setReportData(processedData);
            showSnackbar('Fulfillment report data fetched successfully.', 'success');
        } catch (error) {
            showSnackbar('Failed to fetch fulfillment report data.', 'error');
            console.error('Fetch Fulfillment Report Data Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReportData();
    }, [granularity, startDate, endDate]);

    // Corrected chartData mapping
    const chartData = reportData.map((dataPoint) => ({
        label: dataPoint.clientName,
        '0 Days': dataPoint['0 Days'],
        '1 Day': dataPoint['1 Day'],
        '2 Days': dataPoint['2 Days'],
        '3 Days': dataPoint['3 Days'],
        '4+ Days': dataPoint['4+ Days'],
        AvgFulfillmentTime: dataPoint.AvgFulfillmentTime ? parseFloat(dataPoint.AvgFulfillmentTime.toFixed(2)) : 0.0,
    }));

    // Optional: Log chartData to verify
    useEffect(() => {
        console.log('Chart Data:', chartData);
    }, [chartData]);

    // Define table columns
    const columns = [
        {
            id: 'clientName',
            label: 'Client Name',
            minWidth: 150,
            align: 'left',
            sortable: true, // Enable sorting
        },
        ...(!isMobile ? fulfillmentCategories.map(category => ({
            id: category,
            label: category,
            minWidth: 120,
            align: 'left',
            sortable: false, // Typically, categorical data may not require sorting
        })) : []),
        {
            id: 'AvgFulfillmentTime',
            label: 'Avg Fulfillment Time (Days)',
            minWidth: 180,
            align: 'left',
            sortable: true, // Enable sorting
        },
    ];

    // State for pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ mb: 5 }}>
            {/* Fulfillment Time Report Wrapped in Paper */}
            <Paper elevation={3} sx={{ p: 3, mb: 5 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                    Fulfillment Time Report
                </Typography>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {/* Charts Wrapped in Paper */}
                        <Paper elevation={1} sx={{ p: 2, mb: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                        Fulfillment Counts per Client
                                    </Typography>
                                    <Box sx={{ width: '100%', height: { xs: 300, sm: 400 } }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                data={chartData}
                                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="label" />
                                                <YAxis allowDecimals={false} />
                                                <Tooltip />
                                                <Legend />
                                                {fulfillmentCategories.map((category, index) => (
                                                    <Bar
                                                        key={category}
                                                        dataKey={category}
                                                        stackId="a"
                                                        fill={colors[index % colors.length]}
                                                    />
                                                ))}
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                        Average Fulfillment Time per Client
                                    </Typography>
                                    <Box sx={{ width: '100%', height: { xs: 300, sm: 400 } }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart
                                                data={chartData}
                                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="label" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Line
                                                    type="monotone"
                                                    dataKey="AvgFulfillmentTime"
                                                    stroke="#6f42c1"
                                                    activeDot={{ r: 8 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>

                        {/* Material UI Table Wrapped in CustomTable */}
                        <Paper elevation={1} sx={{ p: 2 }}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Fulfillment Summary Table
                            </Typography>
                            <CustomTable
                                columns={columns}
                                data={reportData.map(row => ({
                                    clientName: row.clientName,
                                    '0 Days': row['0 Days'],
                                    '1 Day': row['1 Day'],
                                    '2 Days': row['2 Days'],
                                    '3 Days': row['3 Days'],
                                    '4+ Days': row['4+ Days'],
                                    AvgFulfillmentTime: row.AvgFulfillmentTime ? row.AvgFulfillmentTime.toFixed(2) : '0.00',
                                }))}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </>
                )}
            </Paper>
        </Box>
    );

};

export default FulfillmentReport;
