// frontend/src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import theme from './theme';
import { AuthProvider } from './contexts/AuthContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import Orders from './components/Orders';
import BagOrders from './components/BagOrders';
import History from './components/History';
import GrafanaKpi from './components/GrafanaKpi';
import Login from './components/Login';
import Profile from './components/Profile';
import UserManagement from './components/UserManagement';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import Clients from './components/Clients';
import AccessDenied from './components/AccessDenied';
import Report from './components/Report';
import Logs from './components/Logs';
import TharsternJobs from './components/TharsternJobs';
import TharsternEstimates from './components/TharsternEstimates';
import TharsternCustomers from './components/TharsternCustomers';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <SnackbarProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Router>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route path="/orders" element={<PrivateRoute component={Orders} />} />
                                <Route path="/bagorders" element={<PrivateRoute component={BagOrders} />} />
                                <Route path="/history" element={<PrivateRoute component={History} />} />
                                <Route path="/grafanakpi" element={<PrivateRoute component={GrafanaKpi} />} />
                                <Route path="/profile" element={<PrivateRoute component={Profile} />} />
                                <Route path="/user-management" element={<PrivateRoute component={UserManagement} />} />
                                <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
                                <Route path="/clients" element={<PrivateRoute component={Clients} />} />
                                <Route path="/reports" element={<PrivateRoute component={Report} />} />
                                <Route path="/tharstern/jobs" element={<PrivateRoute component={TharsternJobs} />} />
                                <Route path="/tharstern/estimates" element={<PrivateRoute component={TharsternEstimates} />} />
                                <Route path="/tharstern/customers" element={<PrivateRoute component={TharsternCustomers} />} />
                                <Route path="/logs" element={<PrivateRoute component={Logs} />} />
                                <Route path="/access-denied" element={<AccessDenied />} />
                                <Route path="/" element={<PrivateRoute component={Dashboard} />} />
                            </Routes>
                        </Router>
                    </LocalizationProvider>
                </SnackbarProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
