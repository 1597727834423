// frontend/src/utils/colorUtils.js

/**
 * Determines the color based on the progress percentage.
 *
 * @param {number} percentage - The progress percentage.
 * @param {object} dispatchPalette - The dispatch palette from the theme.
 * @returns {string} - The corresponding color code.
 */
export const getColorByPercentage = (percentage, dispatchPalette) => {
    if (percentage >= 100) {
        return dispatchPalette.green; // Green
    } else if (percentage >= 50) {
        return dispatchPalette.orange; // Orange
    } else {
        return dispatchPalette.red; // Red
    }
};

/**
 * Determines the color based on the required dispatch value.
 *
 * @param {number} value - The required dispatch number.
 * @param {object} dispatchPalette - The dispatch palette from the theme.
 * @returns {string} - The corresponding color code.
 */
export const getDispatchColor = (value, dispatchPalette) => {
    if (value === 0) {
        return dispatchPalette.green;
    } else {
        return dispatchPalette.red;
    }
};


export const getChipStyles = (status) => {
    switch (status) {
        case 'Error':
            return {
                backgroundColor: '#dc3545', // Red
                color: '#fff',
            };
        case 'Resolved':
            return {
                backgroundColor: '#4caf50', // Green
                color: '#fff',
            };
        case 'In Progress':
            return {
                backgroundColor: '#ff9800', // Orange
                color: '#fff',
            };
        case 'On Hold':
            return {
                backgroundColor: '#9c27b0', // Purple
                color: '#fff',
            };
        case 'Urgent':
                return {
                    backgroundColor: '#F27360', // red
                    color: '#fff',
                }; 
        default:
            return {
                backgroundColor: '#e0e0e0', // Grey for unknown statuses
                color: '#000',
            };
    }
};