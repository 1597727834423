// frontend/src/components/DateRangeSelector.js

import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Grid } from '@mui/material';
import dayjs from 'dayjs';

const DateRangeSelector = ({ selectedOption, setSelectedOption, startDate, setStartDate, endDate, setEndDate }) => {
    // Function to handle option change
    const handleOptionChange = (event) => {
        const option = event.target.value;
        setSelectedOption(option);

        const today = dayjs();
        switch (option) {
            case 'today':
                setStartDate(today.format('YYYY-MM-DD'));
                setEndDate(today.format('YYYY-MM-DD'));
                break;
            case 'yesterday':
                const yesterday = today.subtract(1, 'day');
                setStartDate(yesterday.format('YYYY-MM-DD'));
                setEndDate(yesterday.format('YYYY-MM-DD'));
                break;
            case 'last7days':
                setStartDate(today.subtract(6, 'day').format('YYYY-MM-DD'));
                setEndDate(today.format('YYYY-MM-DD'));
                break;
            case 'last30days':
                setStartDate(today.subtract(29, 'day').format('YYYY-MM-DD'));
                setEndDate(today.format('YYYY-MM-DD'));
                break;
            case 'last60days':
                setStartDate(today.subtract(59, 'day').format('YYYY-MM-DD'));
                setEndDate(today.format('YYYY-MM-DD'));
                break;
            case 'last365days':
                setStartDate(today.subtract(364, 'day').format('YYYY-MM-DD'));
                setEndDate(today.format('YYYY-MM-DD'));
                break;
            case 'lastmonth':
                setStartDate(today.subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
                setEndDate(today.subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
                break;
            case 'last12months':
                setStartDate(today.subtract(12, 'month').startOf('month').format('YYYY-MM-DD'));
                setEndDate(today.subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
                break;
            case 'lastyear':
                setStartDate(today.subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
                setEndDate(today.subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
                break;
            case 'weektodate':
                setStartDate(today.startOf('week').format('YYYY-MM-DD'));
                setEndDate(today.format('YYYY-MM-DD'));
                break;
            case 'monthtodate':
                setStartDate(today.startOf('month').format('YYYY-MM-DD'));
                setEndDate(today.format('YYYY-MM-DD'));
                break;
            case 'quartertodate':
                setStartDate(today.startOf('quarter').format('YYYY-MM-DD'));
                setEndDate(today.format('YYYY-MM-DD'));
                break;
            case 'yeartodate':
                setStartDate(today.startOf('year').format('YYYY-MM-DD'));
                setEndDate(today.format('YYYY-MM-DD'));
                break;
            case 'custom':
                // Allow user to select custom dates
                setStartDate('');
                setEndDate('');
                break;
            default:
                break;
        }
    };

    // Effect to ensure that the DateRangeSelector reflects the initial selectedOption
    useEffect(() => {
        // This effect runs only once on mount to ensure the DateRangeSelector sets the initial dates
        handleOptionChange({ target: { value: selectedOption } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel id="date-range-label">Date Range</InputLabel>
                    <Select
                        labelId="date-range-label"
                        value={selectedOption}
                        label="Date Range"
                        onChange={handleOptionChange}
                    >
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="yesterday">Yesterday</MenuItem>
                        <MenuItem value="last7days">Last 7 Days</MenuItem>
                        <MenuItem value="last30days">Last 30 Days</MenuItem>
                        <MenuItem value="last60days">Last 60 Days</MenuItem>
                        <MenuItem value="last365days">Last 365 Days</MenuItem>
                        <MenuItem value="lastmonth">Last Month</MenuItem>
                        <MenuItem value="last12months">Last 12 Months</MenuItem>
                        <MenuItem value="lastyear">Last Year</MenuItem>
                        <MenuItem value="weektodate">Week to Date</MenuItem>
                        <MenuItem value="monthtodate">Month to Date</MenuItem>
                        <MenuItem value="quartertodate">Quarter to Date</MenuItem>
                        <MenuItem value="yeartodate">Year to Date</MenuItem>
                        <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {selectedOption === 'custom' && (
                <>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Start Date"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="End Date"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );

};

export default DateRangeSelector;
