// frontend/src/components/ClientSummary.js

import React from 'react';
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
} from '@mui/material';
import Grid from '@mui/material/Grid'; // Ensure correct Grid import
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CustomTable from './CustomTable'; // Import the CustomTable component
import moment from 'moment';

const ClientSummary = ({ perClientData, selectedClient, setSelectedClient, clients, granularity }) => {
    return (
        <Box>
            {/* Per-Client Orders Summary Wrapped in Paper */}
            <Paper elevation={3} sx={{ p: 3, mb: 5 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                    Per-Client Orders Summary
                </Typography>
                {/* Select Client Dropdown */}
                <Box sx={{ mb: 3 }}>
                    <FormControl fullWidth>
                        <InputLabel id="client-select-label">Select Client</InputLabel>
                        <Select
                            labelId="client-select-label"
                            id="client-select"
                            value={selectedClient}
                            label="Select Client"
                            onChange={(e) => setSelectedClient(e.target.value)}
                        >
                            <MenuItem value="All">All Clients</MenuItem>
                            {clients.map((client) => (
                                <MenuItem key={client.clientName} value={client.clientName}>
                                    {client.clientName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {perClientData.length > 0 ? (
                    <Grid container spacing={3} alignItems="stretch">
                        {perClientData.map(client => (
                            <Grid item xs={12} sm={6} md={6} lg={6} key={client.clientName}>
                                <Paper
                                    elevation={2}
                                    sx={{
                                        p: 2,
                                        height: '100%', // Make Paper occupy full height of Grid item
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h6" gutterBottom sx={{ color: '#333' }}>
                                            {client.clientName}
                                        </Typography>
                                        <Box sx={{ width: '100%', height: 200 }}>
                                            <ResponsiveContainer>
                                                <BarChart
                                                    data={client.monthlyData}
                                                    margin={{
                                                        top: 20, right: 30, left: 20, bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="label" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    {/* Stacked Bars */}
                                                    <Bar dataKey="DispatchedOrders" stackId="a" fill="#20c997" />
                                                    <Bar dataKey="CancelledOrders" stackId="a" fill="#dc3545" />
                                                    <Bar dataKey="RemainingInProduction" stackId="a" fill="#197DC2" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Box>
                                        {/* Replace Table with CustomTable */}
                                        <Box
                                            sx={{
                                                mt: 2,
                                                flexGrow: 1, // Allow table to take remaining space
                                                overflow: 'auto', // Make table scrollable if content overflows
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ mb: 1, color: '#333' }}>
                                                Orders Details
                                            </Typography>
                                            <CustomTable
                                                columns={[
                                                    {
                                                        id: 'Period',
                                                        label: 'Period',
                                                        minWidth: 150,
                                                        align: 'left',
                                                        sortable: true,
                                                    },
                                                    {
                                                        id: 'ReceivedOrders',
                                                        label: 'Received Orders',
                                                        minWidth: 150,
                                                        align: 'right',
                                                        sortable: true,
                                                    },
                                                    {
                                                        id: 'DispatchedOrders',
                                                        label: 'Dispatched Orders',
                                                        minWidth: 150,
                                                        align: 'right',
                                                        sortable: true,
                                                    },
                                                    {
                                                        id: 'CancelledOrders',
                                                        label: 'Cancelled Orders',
                                                        minWidth: 150,
                                                        align: 'right',
                                                        sortable: true,
                                                    },
                                                ]}
                                                data={client.monthlyData.map(row => ({
                                                    Period: row.label,
                                                    ReceivedOrders: row.ReceivedOrders,
                                                    DispatchedOrders: row.DispatchedOrders,
                                                    CancelledOrders: row.CancelledOrders,
                                                    id: row.id, // Ensure each row has a unique 'id'
                                                }))}
                                                page={0} // Reset pagination for each client
                                                rowsPerPage={10}
                                                handleChangePage={() => {}} // No need to handle page changes here
                                                handleChangeRowsPerPage={() => {}} // No need to handle rows per page changes here
                                                selectable={false} // Disable selection within the summary tables
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        No per-client data available for the selected year and client.
                    </Typography>
                )}
            </Paper>
        </Box>
    );

};

export default ClientSummary;
