// frontend/src/utils/validation.js

export const validatePassword = (password) => {
    // This regex ensures at least:
    // 1 lowercase letter, 1 uppercase letter, and 1 number.
    // Allows special characters but does not require them.
    // The special characters are explicitly listed to avoid issues with characters like ^.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&£^#(){}\[\]:;<>,.?/~_+\-=|\\]{8,}$/;
    return passwordRegex.test(password);
};

export const validateEAN13 = (ean13) => {
    return ean13.startsWith('5') // EAN13 must start with '5'
};

export const validatePAN = (pan) => {
    return (pan.startsWith('6') || pan.startsWith('7')) && !pan.includes(' '); // PAN must start with 6 or 7 and contain no spaces
};

export const validateResellerRefID = (resellerRefID) => {
    const resellerRefPattern = /^[0-9A-Za-z]{11}-[0-9A-Za-z]+$/;
    return resellerRefPattern.test(resellerRefID) && !resellerRefID.includes(' '); // ResellerRefID must match the pattern and contain no spaces
};