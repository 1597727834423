// frontend/src/components/TharsternCustomers.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    CircularProgress,
    Chip,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Layout from './Layout';
import CustomTable from './CustomTable';
import { getTharsternCustomers } from '../services/api'; // Ensure this API function is implemented
import { useSnackbar } from '../contexts/SnackbarContext';
import CustomerDetailModal from './CustomerDetailModal'; // Ensure this component is created

const TharsternCustomers = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        customerRef: '',
        customerName: '',
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [total, setTotal] = useState(0); // Add total state
    const { showSnackbar } = useSnackbar();

    const fetchCustomers = async () => {
        setLoading(true);
        try {
            const response = await getTharsternCustomers({
                customerRef: filters.customerRef,
                customerName: filters.customerName,
                page: page + 1, // API expects 1-based page index
                limit: rowsPerPage,
            });
            if (response.success) {
                setCustomers(response.data);
                setTotal(response.total); // Set total from backend
            } else {
                showSnackbar('Failed to fetch customers.', 'error');
            }
        } catch (error) {
            showSnackbar('Error fetching customers.', 'error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, page, rowsPerPage]);

    useEffect(() => {
        console.log('Total Customers:', total);
    }, [total]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSearch = () => {
        setFilters(prev => ({
            ...prev,
            // No date filters to set
        }));
        setPage(0); // Reset to first page on search
        fetchCustomers(); // Fetch customers with updated filters
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (customer) => { // Receive entire customer object
        setSelectedCustomer(customer);
    };

    const handleModalClose = () => {
        setSelectedCustomer(null);
    };

    return (
        <Layout>
            <Box sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>
                    Tharstern Customers
                </Typography>

                {/* Filters */}
                <Paper sx={{ p: 2, mb: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Customer Reference"
                                name="customerRef"
                                value={filters.customerRef}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Customer Name"
                                name="customerName"
                                value={filters.customerName}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Search />}
                                onClick={handleSearch}
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                        {/* Removed date filters */}
                    </Grid>
                </Paper>

                {/* Customers Table */}
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <CustomTable
                        columns={[
                            { id: 'Customer_Code', label: 'Customer Code', sortable: true },
                            { id: 'Customer_Name', label: 'Customer Name', sortable: true },
                            { id: 'Customer_Country', label: 'Country', sortable: true },
                            {
                                id: 'Customer_Status', label: 'Status', sortable: false,
                                render: (row) => row.Customer_Status ? <Chip label={row.Customer_Status} color="info" /> : 'N/A'
                            },
                            // Add more columns as needed based on your customer data
                        ]}
                        data={customers}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        onRowClick={handleRowClick}
                        total={total} // Pass total to CustomTable
                        rowId="Customer_Code" // Set rowId to match your data's unique identifier
                    />
                )}

                {/* Customer Detail Modal */}
                {selectedCustomer && (
                    <CustomerDetailModal customer={selectedCustomer} open={Boolean(selectedCustomer)} onClose={handleModalClose} />
                )}
            </Box>
        </Layout>
    )
}
export default TharsternCustomers;
