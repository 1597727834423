//frontend/src/services/api.js
import axios from 'axios';

// Set the base URL for axios (you can adjust this based on your backend's URL)
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL || 'https://oms.dpsdigital.co.uk';
axios.defaults.withCredentials = true;  // Ensure cookies are sent

// Add a request interceptor to include the token in the Authorization header
axios.interceptors.request.use(config => {
    const token = sessionStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;  // Set the token in Authorization header
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Create an interceptor to handle 403 responses
axios.interceptors.response.use(
    response => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Token expired or invalid, auto-logout
            sessionStorage.removeItem('token');  // Clear the token
            window.location.href = '/login';  // Redirect to login page
        } else if (error.response && error.response.status === 403) {
            return Promise.reject({ status: 403, message: 'You do not have permission to access this resource' });
        }
        return Promise.reject(error);
    }
);

// Login API call
export const apiLogin = async (credentials) => {
    try {
        const response = await axios.post('api/auth/login', credentials, { withCredentials: true });
        if (response.data.token) {
            sessionStorage.setItem('token', response.data.token);
            return { success: true };
        }
        return { success: false };
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

export const isAuthenticated = () => {
    return !!sessionStorage.getItem('token');
};

export const getClientsOrders = async (filters) => {
    try {
        // Ensure that boolean is passed as a correct query param
        filters.useCutoff = !!filters.useCutoff;  // Convert to boolean

        //console.log("Sending filters to backend:", filters);  // Debugging
        
        const response = await axios.get('/api/orders', { params: filters });
        return response.data;
    } catch (error) {
        console.error("Error fetching orders:", error);
        throw error;
    }
};

export const getClientsName = async () => {
    try {
        const response = await axios.get('/api/orders/clients');
        return response.data;
    } catch (error) {
        console.error("Error fetching clients:", error);
        throw error;
    }
};

// Function to get Grafana KPI
export const getGrafanaKpi = async () => {
    try {
        const response = await axios.get('/api/grafanakpi');
        return response.data;
    } catch (error) {
        console.error("There was an error fetching the Grafana KPI data!", error);
        throw error;
    }
};

//BAG orders
// Function to get orders
export const getOrders = async () => {
    try {
        const response = await axios.get('/api/bagorders');
        return response.data;
    } catch (error) {
        console.error("There was an error fetching the orders data!", error);
        throw error;
    }
};

// Function to add a new order
export const addOrder = async (orderData) => {
    try {
        const response = await axios.post('/api/bagorders/add_order', orderData);
        return response.data;
    } catch (error) {
        console.error("There was an error adding the order!", error);
        throw error;
    }
};

// Function to remove an order
export const removeOrder = async (index) => {
    try {
        const response = await axios.post('/api/bagorders/remove_order', { index });
        return response.data;
    } catch (error) {
        console.error("There was an error removing the order!", error);
        throw error;
    }
};

// Function to export CSV
export const exportCsv = async () => {
    try {
        const response = await axios.get('/api/bagorders/export_csv');
        return response.data;
    } catch (error) {
        console.error("There was an error exporting the CSV!", error);
        throw error;
    }
};

// Function to get history
export const getHistory = async (params) => {
    try {
        const response = await axios.get('/api/history', { params });
        return response.data;
    } catch (error) {
        console.error("There was an error fetching the history data!", error);
        throw error;
    }
};

// Function to log in
export const login = async (credentials) => {
    try {
        const response = await axios.post('/api/login', credentials);
        return response.data;
    } catch (error) {
        console.error("There was an error logging in!", error);
        throw error;
    }
};

// Function to log out
export const logout = async () => {
    try {
        const response = await axios.post('/api/auth/logout');
        sessionStorage.removeItem('token');  // Clear token from sessionStorage
        return response.data;
    } catch (error) {
        console.error('Logout error:', error);
        throw error;
    }
};

// Function to get user profile
export const getProfile = async () => {
    try {
        const token = localStorage.getItem('token'); // Ensure the token is present
        const response = await axios.get('/api/profile', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        //console.log("Profile response:", response.data);  // Log the response data
        return response.data;
    } catch (error) {
        console.error('Error fetching profile data!', error);
        return { success: false, message: error.message };
    }
};

// Function to update two-factor authentication setting
export const updateTwoFactor = async (data) => {
    try {
        const response = await axios.post('/api/profile/update-2fa', data);
        return response.data;
    } catch (error) {
        console.error("There was an error updating the two-factor authentication setting!", error);
        throw error;
    }
};

// Function to manage users (for admins)
export const getUsers = async () => {
    try {
        const response = await axios.get('/api/users');
        return response.data;
    } catch (error) {
        console.error("There was an error fetching the users!", error);
        throw error;
    }
};

// Function to add a new user (for admins)
export const addUser = async (userData) => {
    try {
        const response = await axios.post('/api/users/add', userData);
        return response.data;
    } catch (error) {
        console.error("There was an error adding the user!", error);
        throw error;
    }
};

// Function to edit a user (for admins)
export const editUser = async (userId, userData) => {
    try {
        const response = await axios.post(`/api/users/edit/${userId}`, userData);
        return response.data;
    } catch (error) {
        console.error("There was an error editing the user!", error);
        throw error;
    }
};

// Function to delete a user (for admins)
export const deleteUser = async (userId) => {
    try {
        const response = await axios.post(`/api/users/delete/${userId}`);
        return response.data;
    } catch (error) {
        console.error("There was an error deleting the user!", error);
        throw error;
    }
};

// Function to reset password (for any authenticated user)
export const resetUserPassword = async (userId, newPassword) => {
    try {
        const response = await axios.post(`/api/users/reset-password/${userId}`, { newPassword });  // Send the new password correctly
        return response.data;
    } catch (error) {
        console.error('There was an error resetting the password!', error);
        throw error;
    }
};

//Clients
export const getClients = async () => {
    try {
        const response = await axios.get('/api/clients');
        //console.log('Client getting response:', response);  // Log the response for debugging
        return response.data;
    } catch (error) {
        console.error('Error fetching clients:', error);
        throw error;
    }
};

export const addClient = async (clientData) => {
    try {
        const response = await axios.post('/api/clients/add', clientData);
        //console.log('Client added response:', response);  // Log the response for debugging
        return response.data;
    } catch (error) {
        console.error('Error adding client:', error);
        throw error;
    }
};

export const editClient = async (clientId, clientData) => {
    try {
        const response = await axios.put(`/api/clients/edit/${clientId}`, clientData);
        return response.data;
    } catch (error) {
        console.error('Error editing client:', error);
        throw error;
    }
};

export const deleteClient = async (clientId) => {
    try {
        const response = await axios.delete(`/api/clients/delete/${clientId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting client:', error);
        throw error;
    }
};

export const addCutoffTime = async (clientId, cutoffData) => {
    try {
        const response = await axios.post(`/api/clients/add-cutoff/${clientId}`, cutoffData);
        return response.data;
    } catch (error) {
        console.error('Error adding cutoff time:', error);
        throw error;
    }
};

export const editCutoffTime = async (cutoffId, clientId, cutoffData) => {
    try {
        const response = await axios.put(`/api/clients/edit-cutoff/${cutoffId}/${clientId}`, cutoffData);
        return response.data;
    } catch (error) {
        console.error('Error editing cutoff time:', error);
        throw error;
    }
};

// Function to delete a cutoff time
export const deleteCutoffTime = async (cutoffId) => {
    try {
        const response = await axios.delete(`/api/clients/delete-cutoff/${cutoffId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting cutoff time:', error);
        throw error;
    }
};

export const getDashboardSummary = async () => {
    try {
        const response = await axios.get('/api/dashboard/summary');  // Update to new dashboard route
        return response.data;
    } catch (error) {
        console.error('Error fetching dashboard summary:', error);
        throw error;
    }
};

// Function to get summary based on granularity and date range
export const getSummary = async (granularity, startDate, endDate) => {
    try {
        const response = await axios.get('/api/reports/summary', {
            params: { granularity, startDate, endDate },
        });
        return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error('Error fetching summary:', error);
        throw error;
    }
};

// Function to export summary based on granularity and date range
export const exportSummary = async (granularity, startDate, endDate) => {
    try {
        const response = await axios.get('/api/reports/export-summary', {
            params: { granularity, startDate, endDate },
            responseType: 'blob', // Important for handling binary data
        });
        return response.data;
    } catch (error) {
        console.error('Error exporting summary:', error);
        throw error;
    }
};

// Function to get per-client summary based on granularity and date range
export const getSummaryPerClient = async (granularity, startDate, endDate) => {
    try {
        const response = await axios.get('/api/reports/summary-per-client', {
            params: { granularity, startDate, endDate },
        });
        return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error('Error fetching summary per client:', error);
        throw error;
    }
};

// Function to export per-client summary based on granularity, date range, and optional clientName
export const exportSummaryPerClient = async (granularity, startDate, endDate, clientName) => {
    try {
        const params = { granularity, startDate, endDate };
        if (clientName) {
            params.clientName = clientName;
        }
        const response = await axios.get('/api/reports/export-summary-per-client', {
            params,
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        console.error('Error exporting per-client summary:', error);
        throw error;
    }
};

// Function to get courier dispatch summary based on granularity and date range
export const getCourierDispatchSummary = async (granularity, startDate, endDate) => {
    try {
        const response = await axios.get('/api/reports/courier-dispatch-summary', {
            params: { granularity, startDate, endDate },
        });
        return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error('Error fetching courier dispatch summary:', error);
        throw error;
    }
};

// Function to export courier dispatch summary based on granularity and date range
export const exportCourierDispatchSummary = async (granularity, startDate, endDate) => {
    try {
        const response = await axios.get('/api/reports/export-courier-dispatch-summary', {
            params: { granularity, startDate, endDate },
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        console.error('Error exporting courier dispatch summary:', error);
        throw error;
    }
};

// Function to get fulfillment counts based on granularity and date range
export const getFulfillmentCounts = async (granularity, startDate, endDate) => {
    try {
        const response = await axios.get('/api/reports/fulfillment-counts', {
            params: { granularity, startDate, endDate },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching fulfillment counts:', error);
        throw error;
    }
};

// Function to export fulfillment report based on granularity and date range
export const exportFulfillmentReport = async (granularity, startDate, endDate) => {
    try {
        const response = await axios.get('/api/reports/fulfillment/export', {
            params: { granularity, startDate, endDate },
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        console.error('Error exporting fulfillment report:', error);
        throw error;
    }
};

export const getRequiredDispatchTodayOrders = async (clientName) => {
    try {
        const response = await axios.get(`/api/orders/required-dispatch-today/${clientName}`);
        if (response.data.success) {
            return response.data.orders;
        }
        throw new Error('Failed to fetch required dispatch today orders.');
    } catch (error) {
        console.error(`Error fetching required dispatch today orders for ${clientName}:`, error);
        throw error;
    }
};


// Function to get issue statuses
export const getIssueStatuses = async () => {
    try {
        const response = await axios.get('/api/orders/issue-statuses');
        return response.data;
    } catch (error) {
        console.error('Error fetching issue statuses:', error);
        throw error;
    }
};

// Function to add an order issue
export const addOrderIssue = async (orderId, issueData) => {
    try {
        const response = await axios.post(`/api/orders/${orderId}/issues`, issueData);
        return response.data;
    } catch (error) {
        console.error('Error adding order issue:', error);
        throw error;
    }
};

// Function to get Tharstern Jobs with filters
export const getTharsternJobs = async (params) => {
    try {
        const response = await axios.get('/api/tharstern/jobs', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching Tharstern Jobs:', error);
        throw error;
    }
};

// Function to get Tharstern Estimates with filters
export const getTharsternEstimates = async (params) => {
    try {
        const response = await axios.get('/api/tharstern/estimates', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching Tharstern Estimates:', error);
        throw error;
    }
};

// Function to get Tharstern Customers with filters
export const getTharsternCustomers = async (params) => {
    try {
        const response = await axios.get('/api/tharstern/customers', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching Tharstern Customers:', error);
        throw error;
    }
};
