// frontend/src/components/Profile.js
import React, { useState, useEffect } from 'react';
import { getProfile, updateTwoFactor, resetUserPassword } from '../services/api';  // Ensure these functions are correctly defined in api.js
import Layout from './Layout';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Avatar,
    Switch,
    FormControlLabel,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    Snackbar,
    Alert,
} from '@mui/material';

const Profile = () => {
    const [profile, setProfile] = useState({});
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
    const [passwordData, setPasswordData] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        loadProfile();
    }, []);

    const loadProfile = async () => {
        try {
            const response = await getProfile();
            if (response.success) {
                setProfile(response.user);
                setTwoFactorEnabled(response.user.TwoFactorEnabled);
            } else {
                handleSnackbar('User not found.', 'error'); // Adjust this message based on the response
            }
        } catch (error) {
            console.error('Error loading profile:', error);
            handleSnackbar('Error loading profile.', 'error');
        }
    };

    const handleTwoFactorChange = async () => {
        try {
            await updateTwoFactor({ twoFactorEnabled });
            handleSnackbar('Two-Factor Authentication settings updated.', 'success');
        } catch (error) {
            console.error('Error updating 2FA settings:', error);
            handleSnackbar('Error updating Two-Factor Authentication settings.', 'error');
        }
    };

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        if (passwordData.newPassword !== passwordData.confirmPassword) {
            handleSnackbar('Passwords do not match', 'error');
            return;
        }
        try {
            await resetUserPassword(profile.Id, passwordData.newPassword);
            handleSnackbar('Password reset successfully', 'success');
            setPasswordData({
                newPassword: '',
                confirmPassword: ''
            });
            setOpenResetPassword(false);
        } catch (error) {
            console.error("Error resetting password:", error);
            handleSnackbar("Error resetting password", 'error');
        }
    };

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Grid container spacing={3}>
                    {/* Profile Card */}
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Avatar src="/images/user.png" alt="Profile" sx={{ width: 80, height: 80 }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5">{profile.Username}</Typography>
                                    </Grid>
                                </Grid>
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="body1"><strong>First Name:</strong> {profile.FirstName || 'N/A'}</Typography>
                                    <Typography variant="body1"><strong>Last Name:</strong> {profile.LastName || 'N/A'}</Typography>
                                    <Typography variant="body1"><strong>Email:</strong> {profile.Email || 'N/A'}</Typography>
                                    <Typography variant="body1"><strong>Phone:</strong> {profile.CountryCode || ''} {profile.PhoneNumber || 'N/A'}</Typography>
                                    <Typography variant="body1"><strong>Job Title:</strong> {profile.JobTitle || 'N/A'}</Typography>
                                    <Typography variant="body1"><strong>Department:</strong> {profile.Department || 'N/A'}</Typography>
                                    <Typography variant="body1"><strong>Two-Factor Authentication:</strong> {twoFactorEnabled ? 'Enabled' : 'Disabled'}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Settings Card */}
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Settings</Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={twoFactorEnabled}
                                            onChange={() => setTwoFactorEnabled(!twoFactorEnabled)}
                                        />
                                    }
                                    label="Enable Two-Factor Authentication"
                                />
                                <Box sx={{ mt: 2 }}>
                                    <Button variant="contained" color="primary" onClick={handleTwoFactorChange}>
                                        Update Settings
                                    </Button>
                                    <Button variant="contained" color="warning" sx={{ ml: 2 }} onClick={() => setOpenResetPassword(true)}>
                                        Reset Password
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Reset Password Dialog */}
                <Dialog open={openResetPassword} onClose={() => setOpenResetPassword(false)}>
                    <DialogTitle>Reset Password</DialogTitle>
                    <form onSubmit={handleResetPassword}>
                        <DialogContent>
                            <TextField
                                label="New Password"
                                type="password"
                                fullWidth
                                margin="normal"
                                value={passwordData.newPassword}
                                onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
                                required
                            />
                            <TextField
                                label="Confirm Password"
                                type="password"
                                fullWidth
                                margin="normal"
                                value={passwordData.confirmPassword}
                                onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })}
                                required
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenResetPassword(false)} color="secondary">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="warning">
                                Reset Password
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Snackbar for notifications */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Layout>
    );
};

export default Profile;
