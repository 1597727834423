// frontend/src/components/History.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Layout from './Layout';
import { getHistory } from '../services/api';
import moment from 'moment';
import CustomTable from './CustomTable';

const History = () => {
    const [orders, setOrders] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [ean13, setEan13] = useState('');
    const [pan, setPan] = useState('');
    const [resellerRef, setResellerRef] = useState('');
    const [error, setError] = useState(null);
    const [dateTime, setDateTime] = useState('');

    useEffect(() => {
        const updateDateTime = () => {
            const today = new Date();
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            setDateTime(today.toLocaleDateString('en-US', options));
        };

        updateDateTime(); // Initialize the date and time on load
        const intervalId = setInterval(updateDateTime, 1000); // Update every second
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    const loadHistory = async () => {
        const params = { startDate, endDate, ean13, pan, resellerRef };
        try {
            const response = await getHistory(params);
            setOrders(response.orders);
            setError(response.error);
        } catch (err) {
            console.error('Error fetching history:', err);
            setError('Error fetching history data.');
        }
    };

    // State for pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    const handleSearch = () => {
        loadHistory();
    };

    // Define columns with sortable properties if needed
    const columns = [
        { id: 'EAN13', label: 'EAN13', minWidth: 150, align: 'left', sortable: true },
        { id: 'PAN', label: 'PAN', minWidth: 150, align: 'left', sortable: true },
        { id: 'Transaction Time Stamp', label: 'Transaction Time Stamp', minWidth: 180, align: 'left', sortable: true },
        { id: 'Value', label: 'Value', minWidth: 100, align: 'right', sortable: true },
        { id: 'Store ID', label: 'Store ID', minWidth: 100, align: 'left', sortable: true },
        { id: 'Retailer ID', label: 'Retailer ID', minWidth: 120, align: 'left', sortable: true },
        { id: 'ResellerRefNo', label: 'ResellerRefNo', minWidth: 150, align: 'left', sortable: true },
        { id: 'CreatedDate', label: 'Created Date', minWidth: 150, align: 'left', sortable: true },
        { id: 'ExportedCSV', label: 'Exported CSV', minWidth: 150, align: 'left', sortable: false },
    ];

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    Buy A Gift Report - Entries History
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    {dateTime}
                </Typography>

                {/* Wrap Filters with Paper */}
                <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                label="From"
                                type="date"
                                fullWidth
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                label="To"
                                type="date"
                                fullWidth
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                label="EAN13"
                                fullWidth
                                value={ean13}
                                onChange={(e) => setEan13(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                label="PAN"
                                fullWidth
                                value={pan}
                                onChange={(e) => setPan(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                label="Reseller Ref No."
                                fullWidth
                                value={resellerRef}
                                onChange={(e) => setResellerRef(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Display Error Message if Exists */}
                {error && (
                    <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
                        {error}
                    </Typography>
                )}

                {/* DataGrid for History Table */}
                <Paper sx={{ marginTop: 4 }}>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <CustomTable
                            columns={columns}
                            data={orders.map(row => ({
                                EAN13: row.EAN13,
                                PAN: row.PAN,
                                'Transaction Time Stamp': moment(row.transactionTimeStamp).format('YYYY-MM-DD HH:mm:ss'),
                                Value: row.Value,
                                'Store ID': row['Store ID'],
                                'Retailer ID': row['Retailer ID'],
                                ResellerRefNo: row.ResellerRefNo,
                                CreatedDate: moment(row.createdDate).format('YYYY-MM-DD HH:mm:ss'),
                                ExportedCSV: row.ExportedCSV ? 'Yes' : 'No',
                            }))}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Box>
                </Paper>
            </Box>
        </Layout>
    );
};

export default History;
