// frontend/src/components/Clients.js
import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    TablePagination,
    TableSortLabel,
    Grid,
    CircularProgress,
} from '@mui/material';
import { Edit, Delete, Add, Close, ManageAccounts } from '@mui/icons-material';
import {
    getClients,
    addClient,
    editClient,
    deleteClient,
    addCutoffTime,
    editCutoffTime,
    deleteCutoffTime,
} from '../services/api';
import Layout from './Layout';
import { useSnackbar } from '../contexts/SnackbarContext';
import { useNavigate } from 'react-router-dom';

const Clients = () => {
    const { showSnackbar } = useSnackbar();
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();
    const [openClientDialog, setOpenClientDialog] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);
    const [editClientData, setEditClientData] = useState(null);
    
    // Primary Cutoff Times Modal States
    const [openCutoffDialog, setOpenCutoffDialog] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    
    // Secondary Cutoff Time Form Modal States
    const [openCutoffFormDialog, setOpenCutoffFormDialog] = useState(false);
    const [cutoffFormData, setCutoffFormData] = useState({
        shipping_carrier: '',
        shipping_method: '',
        shipping_method_condition: 'equals',
        cutoff_time: '',
        cutoff_day_offset: 0,
    });
    const [editCutoffId, setEditCutoffId] = useState(null);
    
    const [confirmCutoffDeleteDialog, setConfirmCutoffDeleteDialog] = useState(false);
    const [cutoffToDelete, setCutoffToDelete] = useState(null);

    // Loading States
    const [isLoading, setIsLoading] = useState(false);
    const [isSavingClient, setIsSavingClient] = useState(false);
    const [isSavingCutoff, setIsSavingCutoff] = useState(false);
    
    // Pagination and Sorting States
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('clientName');
    const [searchTerm, setSearchTerm] = useState('');

    // Predefined list of shipping carriers
    const carriers = ['Royal Mail', 'Evri', 'Hermes', 'Parcelforce', 'DPD', 'DHL', 'Fedex', 'UPS'];

    const fetchClients = async () => {
        setIsLoading(true);
        try {
            const response = await getClients();
            setClients(response);
            if (selectedClient) {
                const updatedClient = response.find(client => client.clientId === selectedClient.clientId);
                setSelectedClient(updatedClient);
            }
        } catch (error) {
            if (error.status === 403) {
                showSnackbar('You do not have permission to access this resource', 'error');
                navigate('/access-denied'); // Redirect to Access Denied page
            } else {
                showSnackbar('Failed to load clients.', 'error');
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const handleOpenAddClient = () => {
        setEditClientData({
            clientName: '',
            fulfilmentChannel: '',
        });
        setOpenClientDialog(true);
    };

    const handleOpenEditClient = (client) => {
        setEditClientData(client);
        setOpenClientDialog(true);
    };

    const handleCloseClientDialog = () => {
        setOpenClientDialog(false);
        setEditClientData(null);
    };

    const handleSaveClient = async () => {
        // Form Validation
        if (!editClientData.clientName.trim() || !editClientData.fulfilmentChannel.trim()) {
            showSnackbar('Please fill in all required fields.', 'warning');
            return;
        }

        setIsSavingClient(true);
        try {
            if (editClientData && editClientData.clientId) {
                // Editing an existing client
                await editClient(editClientData.clientId, {
                    clientName: editClientData.clientName,
                    fulfilmentChannel: editClientData.fulfilmentChannel,
                });
                showSnackbar('Client updated successfully.', 'success');
            } else {
                // Adding a new client
                await addClient({
                    clientName: editClientData.clientName,
                    fulfilmentChannel: editClientData.fulfilmentChannel,
                });
                showSnackbar('Client added successfully.', 'success');
            }
            fetchClients();
            handleCloseClientDialog();
        } catch (error) {
            console.error('Error saving client:', error);
            showSnackbar('Failed to save client.', 'error');
        } finally {
            setIsSavingClient(false);
        }
    };

    const handleOpenDeleteClient = (clientId) => {
        setClientToDelete(clientId);
        setConfirmDeleteDialog(true);
    };

    const handleCloseDeleteClient = () => {
        setClientToDelete(null);
        setConfirmDeleteDialog(false);
    };

    const handleDeleteClient = async () => {
        try {
            await deleteClient(clientToDelete);
            showSnackbar('Client deleted successfully.', 'success');
            fetchClients();
            handleCloseDeleteClient();
        } catch (error) {
            console.error('Error deleting client:', error);
            showSnackbar('Failed to delete client.', 'error');
        }
    };

    // Primary Modal Handlers
    const handleOpenManageCutoff = (client) => {
        setSelectedClient(client);
        setOpenCutoffDialog(true);
    };

    const handleCloseManageCutoff = () => {
        setSelectedClient(null);
        setOpenCutoffDialog(false);
    };

    // Secondary Modal Handlers
    const handleOpenAddCutoff = () => {
        setCutoffFormData({
            shipping_carrier: '',
            shipping_method: '',
            shipping_method_condition: 'equals',
            cutoff_time: '',
            cutoff_day_offset: 0,
        });
        setEditCutoffId(null);
        setOpenCutoffFormDialog(true);
    };

    const handleOpenEditCutoff = (cutoff) => {
        setCutoffFormData({
            shipping_carrier: cutoff.shipping_carrier,
            shipping_method: cutoff.shipping_method,
            shipping_method_condition: cutoff.shipping_method_condition || 'equals',
            cutoff_time: formatTime(cutoff.cutoff_time),
            cutoff_day_offset: cutoff.cutoff_day_offset,
        });
        setEditCutoffId(cutoff.cutoffId);
        setOpenCutoffFormDialog(true);
    };

    const handleCloseCutoffForm = () => {
        setCutoffFormData({
            shipping_carrier: '',
            shipping_method: '',
            shipping_method_condition: 'equals',
            cutoff_time: '',
            cutoff_day_offset: 0,
        });
        setEditCutoffId(null);
        setOpenCutoffFormDialog(false);
    };

    const handleSaveCutoff = async () => {
        // Form Validation
        if (
            !cutoffFormData.shipping_carrier.trim() ||
            !cutoffFormData.shipping_method.trim() ||
            !cutoffFormData.cutoff_time.trim()
        ) {
            showSnackbar('Please fill in all required fields.', 'warning');
            return;
        }

        setIsSavingCutoff(true);
        try {
            if (editCutoffId) {
                // Editing an existing cutoff time
                await editCutoffTime(editCutoffId, selectedClient.clientId, cutoffFormData);
                showSnackbar('Cutoff time updated successfully.', 'success');
            } else {
                // Adding a new cutoff time
                await addCutoffTime(selectedClient.clientId, cutoffFormData);
                showSnackbar('Cutoff time added successfully.', 'success');
            }
            await fetchClients(); // Refresh clients and update selectedClient
            handleCloseCutoffForm(); // Close the secondary modal
            // No need to close and reopen the primary modal
        } catch (error) {
            console.error('Error saving cutoff time:', error);
            showSnackbar('Failed to save cutoff time.', 'error');
        } finally {
            setIsSavingCutoff(false);
        }
    };

    const handleOpenDeleteCutoff = (cutoffId) => {
        setCutoffToDelete(cutoffId);
        setConfirmCutoffDeleteDialog(true);
    };

    const handleCloseDeleteCutoff = () => {
        setCutoffToDelete(null);
        setConfirmCutoffDeleteDialog(false);
    };

    const handleDeleteCutoff = async () => {
        try {
            await deleteCutoffTime(cutoffToDelete);
            showSnackbar('Cutoff time deleted successfully.', 'success');
            await fetchClients(); // Refresh clients and update selectedClient
            handleCloseDeleteCutoff();
        } catch (error) {
            console.error('Error deleting cutoff time:', error);
            showSnackbar('Failed to delete cutoff time.', 'error');
        }
    };

    const formatTime = (timeString) => {
        if (timeString && timeString.includes('T')) {
            const timePart = timeString.split('T')[1];
            const [hours, minutes] = timePart.split(':');
            return `${hours}:${minutes}`;
        }
        return timeString; // Return as is if already formatted
    };

    // Sorting Functions
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedClients = () => {
        return [...clients].sort((a, b) => {
            const aValue = a[orderBy].toLowerCase();
            const bValue = b[orderBy].toLowerCase();
            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        });
    };

    // Filtering Clients based on Search
    const filteredClients = sortedClients().filter(client =>
        client.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.fulfilmentChannel.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination Handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    Clients Management
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={handleOpenAddClient}
                    >
                        Add New Client
                    </Button>
                    <TextField
                        label="Search Clients"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Box>

                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'clientName'}
                                            direction={orderBy === 'clientName' ? order : 'asc'}
                                            onClick={() => handleRequestSort('clientName')}
                                        >
                                            Client Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'fulfilmentChannel'}
                                            direction={orderBy === 'fulfilmentChannel' ? order : 'asc'}
                                            onClick={() => handleRequestSort('fulfilmentChannel')}
                                        >
                                            Fulfilment Channel
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client) => (
                                    <TableRow key={client.clientId}>
                                        <TableCell>{client.clientName}</TableCell>
                                        <TableCell>{client.fulfilmentChannel}</TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Edit Client">
                                                <IconButton color="primary" onClick={() => handleOpenEditClient(client)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete Client">
                                                <IconButton color="secondary" onClick={() => handleOpenDeleteClient(client.clientId)}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Manage Cutoff Times">
                                                <IconButton color="info" onClick={() => handleOpenManageCutoff(client)}>
                                                    <ManageAccounts />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {filteredClients.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">
                                            No clients found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <TablePagination
                    component="div"
                    count={filteredClients.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                />

                {/* Add/Edit Client Dialog */}
                <Dialog open={openClientDialog} onClose={handleCloseClientDialog}>
                    <DialogTitle>{editClientData && editClientData.clientId ? 'Edit Client' : 'Add New Client'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            label="Client Name"
                            type="text"
                            fullWidth
                            value={editClientData ? editClientData.clientName : ''}
                            onChange={(e) => setEditClientData({ ...editClientData, clientName: e.target.value })}
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Fulfilment Channel</InputLabel>
                            <Select
                                value={editClientData ? editClientData.fulfilmentChannel : ''}
                                onChange={(e) => setEditClientData({ ...editClientData, fulfilmentChannel: e.target.value })}
                                label="Fulfilment Channel"
                            >
                                <MenuItem value="KornitX">KornitX</MenuItem>
                                <MenuItem value="Mintsoft">Mintsoft</MenuItem>
                                <MenuItem value="Veeqo">Veeqo</MenuItem>
                                <MenuItem value="Siteflow">Siteflow</MenuItem>
                                {/* Add more fulfilment channels as needed */}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseClientDialog} color="secondary" startIcon={<Close />}>
                            Cancel
                        </Button>
                        <Button onClick={handleSaveClient} color="primary" startIcon={<Add />} disabled={isSavingClient}>
                            {editClientData && editClientData.clientId ? 'Update Client' : 'Add Client'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Confirm Delete Client Dialog */}
                <Dialog open={confirmDeleteDialog} onClose={handleCloseDeleteClient}>
                    <DialogTitle>Delete Client</DialogTitle>
                    <DialogContent>Are you sure you want to delete this client? This action cannot be undone.</DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteClient} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteClient} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Primary Cutoff Times Dialog */}
                <Dialog
                    open={openCutoffDialog}
                    onClose={handleCloseManageCutoff}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>
                        Manage Cutoff Times for {selectedClient ? selectedClient.clientName : ''}
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={handleOpenAddCutoff}
                            >
                                Add Cutoff Time
                            </Button>
                        </Box>

                        {/* Cutoff Times Table */}
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Shipping Carrier</TableCell>
                                        <TableCell>Condition</TableCell>
                                        <TableCell>Shipping Method</TableCell>
                                        <TableCell>Cutoff Time</TableCell>
                                        <TableCell>Day Offset</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedClient && selectedClient.cutoffTimes && selectedClient.cutoffTimes.length > 0 ? (
                                        selectedClient.cutoffTimes.map((cutoff) => (
                                            <TableRow key={cutoff.cutoffId}>
                                                <TableCell>{cutoff.shipping_carrier}</TableCell>
                                                <TableCell>{cutoff.shipping_method_condition}</TableCell>
                                                <TableCell>{cutoff.shipping_method}</TableCell>
                                                <TableCell>{formatTime(cutoff.cutoff_time)}</TableCell>
                                                <TableCell>{cutoff.cutoff_day_offset}</TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Edit Cutoff Time">
                                                        <IconButton color="primary" onClick={() => handleOpenEditCutoff(cutoff)}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete Cutoff Time">
                                                        <IconButton color="secondary" onClick={() => handleOpenDeleteCutoff(cutoff.cutoffId)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                No cutoff times available.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseManageCutoff} color="secondary" startIcon={<Close />}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Secondary Add/Edit Cutoff Time Form Dialog */}
                <Dialog
                    open={openCutoffFormDialog}
                    onClose={handleCloseCutoffForm}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>{editCutoffId ? 'Edit Cutoff Time' : 'Add Cutoff Time'}</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
                            <FormControl fullWidth>
                                <InputLabel>Shipping Carrier</InputLabel>
                                <Select
                                    value={cutoffFormData.shipping_carrier}
                                    onChange={(e) => setCutoffFormData({ ...cutoffFormData, shipping_carrier: e.target.value })}
                                    label="Shipping Carrier"
                                >
                                    {carriers.map((carrier) => (
                                        <MenuItem key={carrier} value={carrier}>
                                            {carrier}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel>Condition</InputLabel>
                                <Select
                                    value={cutoffFormData.shipping_method_condition}
                                    onChange={(e) => setCutoffFormData({ ...cutoffFormData, shipping_method_condition: e.target.value })}
                                    label="Condition"
                                >
                                    <MenuItem value="equals">Equals</MenuItem>
                                    <MenuItem value="not equals">Not Equals</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label="Shipping Method"
                                type="text"
                                fullWidth
                                value={cutoffFormData.shipping_method}
                                onChange={(e) => setCutoffFormData({ ...cutoffFormData, shipping_method: e.target.value })}
                            />
                            <TextField
                                label="Cutoff Time"
                                type="time"
                                fullWidth
                                value={cutoffFormData.cutoff_time}
                                onChange={(e) => setCutoffFormData({ ...cutoffFormData, cutoff_time: e.target.value })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                            <TextField
                                label="Day Offset"
                                type="number"
                                fullWidth
                                value={cutoffFormData.cutoff_day_offset}
                                onChange={(e) =>
                                    setCutoffFormData({ ...cutoffFormData, cutoff_day_offset: parseInt(e.target.value) || 0 })
                                }
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCutoffForm} color="secondary" startIcon={<Close />}>
                            Cancel
                        </Button>
                        <Button onClick={handleSaveCutoff} color="primary" startIcon={<Add />} disabled={isSavingCutoff}>
                            {editCutoffId ? 'Update Cutoff Time' : 'Add Cutoff Time'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Confirm Delete Cutoff Time Dialog */}
                <Dialog open={confirmCutoffDeleteDialog} onClose={handleCloseDeleteCutoff}>
                    <DialogTitle>Delete Cutoff Time</DialogTitle>
                    <DialogContent>Are you sure you want to delete this cutoff time? This action cannot be undone.</DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteCutoff} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteCutoff} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Layout>
    );

};

export default Clients;
