// frontend/src/components/LoadingIndicator.js
import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingIndicator = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',  // Full height of the viewport
            }}
        >
            <CircularProgress size={60} thickness={5}/>
        </Box>
    );
};

export default LoadingIndicator;
