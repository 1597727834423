// frontend/src/components/Footer.js
import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                py: 2,
                backgroundColor: '#f5f5f5',
                mt: 4,
                borderTop: '1px solid #ddd',
            }}
        >
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={6} textAlign="center">
                    <Typography variant="body2" color="textSecondary">
                        &copy; {new Date().getFullYear()} IV Creative Limited. All rights reserved.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign="center">
                    <Typography variant="body2" color="textSecondary">
                        <Link href="https://iv-creative.co.uk/terms-and-conditions/" color="inherit" underline="hover">
                            Terms and Conditions
                        </Link>
                        {' | '}
                        <Link href="https://iv-creative.co.uk/privacy-policy/" color="inherit" underline="hover">
                            Privacy Policy
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;
