// frontend/src/components/PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const PrivateRoute = ({ component: Component, permission }) => {
    const { authenticated, permissions } = useContext(AuthContext);

    if (!authenticated) {
        return <Navigate to="/login" />;
    }

    if (permission && !permissions.includes(permission)) {
        return <Navigate to="/access-denied" />;  // Redirect to access-denied if permission is missing
    }

    return <Component />;
};

export default PrivateRoute;
