// frontend/src/components/JobDetailModal.js

import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Grid,
    IconButton,
    Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
};

const JobDetailModal = ({ job, open, onClose }) => {
    if (!job) return null;

    const jobDetails = [
        { label: 'Job Number', value: job.JobNo },
        { label: 'Required Time', value: job.RequiredTime },
        { label: 'Delivery Customer Code', value: job.DeliveryCustomerCode },
        { label: 'Invoice Customer Code', value: job.InvoiceCustomerCode },
        { label: 'Invoice Customer Name', value: job.InvoiceCustomerName },
        { label: 'Invoice Address', value: job.InvoiceAddress },
        { label: 'Invoice Town', value: job.InvoiceTown },
        { label: 'Invoice County', value: job.InvoiceCounty },
        { label: 'Invoice Country', value: job.InvoiceCountry },
        { label: 'Invoice Postcode', value: job.InvoicePostcode },
        { label: 'Invoice Contact', value: job.InvoiceContact },
        { label: 'Delivery Customer Name', value: job.DeliveryCustomerName },
        { label: 'Delivery Address', value: job.DeliveryAddress },
        { label: 'Delivery Town', value: job.DeliveryTown },
        { label: 'Delivery County', value: job.DeliveryCounty },
        { label: 'Delivery Country', value: job.DeliveryCountry },
        { label: 'Delivery Postcode', value: job.DeliveryPostCode },
        { label: 'Delivery Contact', value: job.DeliveryContact },
        { label: 'Delivery Email', value: job.DeliveryEmail },
        { label: 'Delivery Tel', value: job.DeliveryTel },
        { label: 'Job Description', value: job.JobDesc },
        { label: 'Quantity Ordered', value: job.QuantityOrdered },
        { label: 'Quantity Produced', value: job.QuantityProduced },
        { label: 'Quantity Delivered', value: job.QuantityDelivered },
        { label: 'Quantity Invoiced', value: job.QuantityInvoiced },
        { label: 'Job Completed', value: job.JobCompleted ? 'Yes' : 'No' },
        { label: 'Invoice Completed', value: job.InvoiceCompleted ? 'Yes' : 'No' },
        { label: 'Job Cancelled', value: job.JobCancelled ? 'Yes' : 'No' },
        { label: 'Out on Proof', value: job.OutOnProof ? 'Yes' : 'No' },
        { label: 'Job Completion Date', value: job.JobCompletionDate ? dayjs(job.JobCompletionDate).format('YYYY-MM-DD') : 'N/A' },
        { label: 'Estimate Header Ref', value: job.EstimateHeaderRef },
        { label: 'Job Type', value: job.JobType },
        { label: 'Job Type Description', value: job.JobTypeDesc },
        { label: 'Created By', value: job.CreateUserName },
        { label: 'Weight', value: job.Weight },
        { label: 'Parent Job', value: job.ParentJob },
        { label: 'Is Invoiced', value: job.IsInvoiced ? 'Yes' : 'No' },
        { label: 'Finished Production Date', value: job.FinishedProductionDate ? dayjs(job.FinishedProductionDate).format('YYYY-MM-DD') : 'N/A' },
        { label: 'Unit Price', value: `$${job.UnitPrice.toFixed(2)}` },
        { label: 'Created Date Time', value: job.CreateDateTime ? dayjs(job.CreateDateTime).format('YYYY-MM-DD HH:mm:ss') : 'N/A' },
        // Add more fields as necessary
    ];

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="job-detail-modal-title"
            aria-describedby="job-detail-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography id="job-detail-modal-title" variant="h6" component="h2">
                        Job Details
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={2}>
                    {jobDetails.map((detail, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    {detail.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1">
                                    {detail.value}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                {/* Add more sections as needed */}
            </Box>
        </Modal>
    );
};

export default JobDetailModal;
