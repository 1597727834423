// frontend/src/components/Report.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    CircularProgress,
    Tabs,
    Tab,
    ToggleButton,
    ToggleButtonGroup,
    TextField,
    Grid,
    Paper, // Import Paper
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import Layout from './Layout';
import {
    getSummary,
    getSummaryPerClient,
    exportSummary,
    exportSummaryPerClient,
    getClientsName,
    exportCourierDispatchSummary,
    exportFulfillmentReport,
    getCourierDispatchSummary,
    getFulfillmentCounts,
} from '../services/api';
import { useSnackbar } from '../contexts/SnackbarContext';
import OverallSummary from './OverallSummary';
import ClientSummary from './ClientSummary';
import CourierDispatchReport from './CourierDispatchReport';
import FulfillmentReport from './FulfillmentReport';
import moment from 'moment';

// Import MUI's styled utility
import { styled } from '@mui/material/styles';

// **StyledTabs Component**
const StyledTabs = styled(Tabs)(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
        width: '4px', // Thicker indicator for better visibility
    },
}));

// **StyledTab Component**
const StyledTab = styled(Tab)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1.5, 2), // Vertical and horizontal padding
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '12px',
    minHeight: '48px', // Ensures consistent height
    '& .MuiTab-wrapper': {
        alignItems: 'center', // Centers icon and label vertically
        justifyContent: 'flex-start', // Aligns label to the left
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        borderRadius: '4px 0 0 4px',
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const Report = () => {
    const [granularity, setGranularity] = useState('Day');
    const [availableGranularities, setAvailableGranularities] = useState(['Day', 'Week', 'Month']);
    const [summaryData, setSummaryData] = useState([]);
    const [clientSummaryData, setClientSummaryData] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('All');
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const { showSnackbar } = useSnackbar();
    const [tabIndex, setTabIndex] = useState(0);

    // **Set default date range option to 'last7days'**
    const [dateRangeOption, setDateRangeOption] = useState('last7days');

    // **Function to get date ranges based on selected option**
    const getDateRange = (selectedOption) => {
        let newStartDate;
        let newEndDate = moment().endOf('day');

        switch (selectedOption) {
            case 'today':
                newStartDate = moment().startOf('day');
                newEndDate = moment().endOf('day');
                break;
            case 'yesterday':
                newStartDate = moment().subtract(1, 'days').startOf('day');
                newEndDate = moment().subtract(1, 'days').endOf('day');
                break;
            case 'last7days':
                newStartDate = moment().subtract(6, 'days').startOf('day');
                newEndDate = moment().endOf('day');
                break;
            case 'last30days':
                newStartDate = moment().subtract(29, 'days').startOf('day');
                newEndDate = moment().endOf('day');
                break;
            case 'last60days':
                newStartDate = moment().subtract(59, 'days').startOf('day');
                newEndDate = moment().endOf('day');
                break;
            case 'last365days':
                newStartDate = moment().subtract(364, 'days').startOf('day');
                newEndDate = moment().endOf('day');
                break;
            case 'lastmonth':
                newStartDate = moment().subtract(1, 'months').startOf('month');
                newEndDate = moment().subtract(1, 'months').endOf('month');
                break;
            case 'last12months':
                newStartDate = moment().subtract(12, 'months').startOf('month');
                newEndDate = moment().subtract(1, 'months').endOf('month');
                break;
            case 'lastyear':
                newStartDate = moment().subtract(1, 'years').startOf('year');
                newEndDate = moment().subtract(1, 'years').endOf('year');
                break;
            case 'weektodate':
                newStartDate = moment().startOf('week');
                newEndDate = moment().endOf('day');
                break;
            case 'monthtodate':
                newStartDate = moment().startOf('month');
                newEndDate = moment().endOf('day');
                break;
            case 'quartertodate':
                newStartDate = moment().startOf('quarter');
                newEndDate = moment().endOf('day');
                break;
            case 'yeartodate':
                newStartDate = moment().startOf('year');
                newEndDate = moment().endOf('day');
                break;
            case 'custom':
                // Do not change startDate and endDate
                return null;
            default:
                newStartDate = moment().startOf('day');
                newEndDate = moment().endOf('day');
        }

        return {
            startDate: newStartDate.format('YYYY-MM-DD'),
            endDate: newEndDate.format('YYYY-MM-DD'),
        };
    };

    // **Initialize startDate and endDate based on 'last7days'**
    const initialDateRange = getDateRange('last7days') || { startDate: '', endDate: '' };
    const [startDate, setStartDate] = useState(initialDateRange.startDate);
    const [endDate, setEndDate] = useState(initialDateRange.endDate);

    const dateRangeOptions = [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Last 7 Days', value: 'last7days' },
        { label: 'Last 30 Days', value: 'last30days' },
        { label: 'Last 60 Days', value: 'last60days' },
        { label: 'Last 365 Days', value: 'last365days' },
        { label: 'Last Month', value: 'lastmonth' },
        { label: 'Last 12 Months', value: 'last12months' },
        { label: 'Last Year', value: 'lastyear' },
        { label: 'Week to Date', value: 'weektodate' },
        { label: 'Month to Date', value: 'monthtodate' },
        { label: 'Quarter to Date', value: 'quartertodate' },
        { label: 'Year to Date', value: 'yeartodate' },
        { label: 'Custom', value: 'custom' },
    ];

    const handleDateRangeOptionChange = (event) => {
        const selectedOption = event.target.value;
        setDateRangeOption(selectedOption);

        const dateRange = getDateRange(selectedOption);
        if (dateRange) {
            setStartDate(dateRange.startDate);
            setEndDate(dateRange.endDate);
        }
        // For 'custom', do not change startDate and endDate
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
        setDateRangeOption('custom');
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
        setDateRangeOption('custom');
    };

    // **Effect to adjust granularity options based on date range**
    useEffect(() => {
        const diffInDays = moment(endDate).diff(moment(startDate), 'days') + 1; // Include the end date

        if (diffInDays > 90) {
            setAvailableGranularities(['Month']);
            setGranularity('Month');
        } else {
            setAvailableGranularities(['Day', 'Week', 'Month']);
            // If the current granularity is 'Month' due to previous selection, set it back to 'Day'
            if (granularity === 'Month') {
                setGranularity('Day');
            }
        }
    }, [startDate, endDate]);

    const fetchSummary = async () => {
        setLoading(true);
        try {
            const summaryData = await getSummary(granularity, startDate, endDate);
            setSummaryData(Array.isArray(summaryData) ? summaryData : []);

            const clientData = await getSummaryPerClient(granularity, startDate, endDate);
            setClientSummaryData(Array.isArray(clientData) ? clientData : []);

            const clientsList = await getClientsName();

            if (clientsList.success && Array.isArray(clientsList.clients)) {
                setClients(clientsList.clients);
            } else {
                setClients([]);
                console.warn('Failed to fetch clients or clients data is not an array.');
            }

            showSnackbar('Summary fetched successfully.', 'success');
        } catch (error) {
            showSnackbar('Failed to fetch summary.', 'error');
            console.error('Fetch Summary Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSummary();
    }, [granularity, startDate, endDate]);

    const handleExport = async () => {
        setExporting(true);
        try {
            if (tabIndex === 0) {
                const blob = await exportSummary(granularity, startDate, endDate);
                const filename = `summary_${granularity.toLowerCase()}_${startDate}-${endDate}.csv`;
                const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                showSnackbar('Overall Summary exported successfully.', 'success');
            } else if (tabIndex === 1) {
                const clientToExport = selectedClient === 'All' ? null : selectedClient;
                const blob = await exportSummaryPerClient(granularity, startDate, endDate, clientToExport);
                const filename = `per_client_summary_${granularity.toLowerCase()}_${startDate}-${endDate}.csv`;
                const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                showSnackbar('Per-Client Summary exported successfully.', 'success');
            } else if (tabIndex === 2) {
                const blob = await exportCourierDispatchSummary(granularity, startDate, endDate);
                const filename = `courier_dispatch_summary_${granularity.toLowerCase()}_${startDate}-${endDate}.csv`;
                const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                showSnackbar('Courier Dispatch Summary exported successfully.', 'success');
            } else if (tabIndex === 3) {
                const blob = await exportFulfillmentReport(granularity, startDate, endDate);
                const filename = `fulfillment_report_${granularity.toLowerCase()}_${startDate}-${endDate}.csv`;
                const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                showSnackbar('Fulfillment Report exported successfully.', 'success');
            }
        } catch (error) {
            showSnackbar('Failed to export summary.', 'error');
            console.error('Export Error:', error);
        } finally {
            setExporting(false);
        }
    };

    // Prepare data for the overall chart based on granularity
    const chartData = summaryData.map((dataPoint) => {
        let label = '';
        if (granularity === 'Day') {
            label = moment(dataPoint.date).format('YYYY-MM-DD');
        } else if (granularity === 'Week') {
            label = moment(dataPoint.weekStartDate).format('YYYY-MM-DD');
        } else {
            label = moment(`${dataPoint.year}-${dataPoint.month}-01`).format('YYYY MMM');
        }

        const remainingInProduction = Math.max(
            0,
            dataPoint.receivedOrders - dataPoint.dispatchedOrders - dataPoint.cancelledOrders
        );

        return {
            label,
            ReceivedOrders: dataPoint.receivedOrders,
            DispatchedOrders: dataPoint.dispatchedOrders,
            CancelledOrders: dataPoint.cancelledOrders,
            RemainingInProduction: remainingInProduction,
        };
    });

    // Filter per-client summary data
    const filteredClientSummaryData = selectedClient === 'All'
        ? clientSummaryData
        : clientSummaryData.filter(item => item.clientName === selectedClient);

    const perClientData = Array.from(new Set(filteredClientSummaryData.map(item => item.clientName)))
        .sort()
        .map(client => {
            const clientData = filteredClientSummaryData.filter(item => item.clientName === client);
            const formattedData = clientData.map(item => {
                let label = '';
                if (granularity === 'Day') {
                    label = moment(item.date).format('YYYY-MM-DD');
                } else if (granularity === 'Week') {
                    label = `Week Starting ${moment(item.weekStartDate).format('YYYY-MM-DD')}`;
                } else {
                    label = moment(`${item.year}-${item.month}-01`).format('YYYY MMM');
                }

                const remainingInProduction = Math.max(
                    0,
                    item.receivedOrders - item.dispatchedOrders - item.cancelledOrders
                );

                return {
                    label,
                    ReceivedOrders: item.receivedOrders,
                    DispatchedOrders: item.dispatchedOrders,
                    CancelledOrders: item.cancelledOrders,
                    RemainingInProduction: remainingInProduction,
                };
            });
            return {
                clientName: client,
                monthlyData: formattedData,
            };
        });

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Layout>
            <Box sx={{ p: { xs: 2, sm: 3 }, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
                {/* Header Section Wrapped in Paper */}
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                        Orders Report
                    </Typography>
                </Box>

                {/* Filters and Export Button Wrapped in Paper */}
                <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6} md={3}>
                            <ToggleButtonGroup
                                value={granularity}
                                exclusive
                                onChange={(event, newGranularity) => {
                                    if (newGranularity !== null) {
                                        setGranularity(newGranularity);
                                    }
                                }}
                                aria-label="Granularity"
                                fullWidth
                            >
                                <ToggleButton value="Day" aria-label="Day" disabled={!availableGranularities.includes('Day')}>
                                    Day
                                </ToggleButton>
                                <ToggleButton value="Week" aria-label="Week" disabled={!availableGranularities.includes('Week')}>
                                    Week
                                </ToggleButton>
                                <ToggleButton value="Month" aria-label="Month">
                                    Month
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="date-range-option-label">Date Range</InputLabel>
                                <Select
                                    labelId="date-range-option-label"
                                    id="date-range-option"
                                    value={dateRangeOption}
                                    label="Date Range"
                                    onChange={handleDateRangeOptionChange}
                                >
                                    {dateRangeOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                type="date"
                                label="Start Date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                type="date"
                                label="End Date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<DownloadIcon />}
                                onClick={handleExport}
                                disabled={exporting}
                                fullWidth
                            >
                                {exporting ? 'Exporting...' : 'Export CSV'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Vertical Tabs and Content Wrapped in Paper */}
                <Paper elevation={3} sx={{ p: 3 }}>
                    <Grid container spacing={2}>
                        {/* Tabs Section */}
                        <Grid item xs={12} sm={3} md={2}>
                            <StyledTabs
                                orientation="vertical"
                                variant="scrollable"
                                value={tabIndex}
                                onChange={handleTabChange}
                                aria-label="Report Tabs"
                                sx={{ height: '100%' }} // Ensure tabs take full height
                            >
                                <StyledTab label="Overall Summary" id="tab-0" aria-controls="tabpanel-0" />
                                <StyledTab label="Per-Client Summaries" id="tab-1" aria-controls="tabpanel-1" />
                                <StyledTab label="Courier Dispatch Report" id="tab-2" aria-controls="tabpanel-2" />
                                <StyledTab label="Fulfillment Time Report" id="tab-3" aria-controls="tabpanel-3" />
                            </StyledTabs>
                        </Grid>

                        {/* Content Section */}
                        <Grid item xs={12} sm={9} md={10}>
                            {/* Loading Indicator */}
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    {/* Tab Panels */}
                                    <TabPanel value={tabIndex} index={0}>
                                        <OverallSummary summaryData={summaryData} granularity={granularity} chartData={chartData} />
                                    </TabPanel>

                                    <TabPanel value={tabIndex} index={1}>
                                        <ClientSummary
                                            perClientData={perClientData}
                                            selectedClient={selectedClient}
                                            setSelectedClient={setSelectedClient}
                                            clients={clients}
                                            granularity={granularity}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={2}>
                                        <CourierDispatchReport
                                            granularity={granularity}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={3}>
                                        <FulfillmentReport
                                            granularity={granularity}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    </TabPanel>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Layout>
    );
}

// TabPanel component for rendering tab content
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default Report;
