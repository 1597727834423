// frontend/src/components/Cards/SummaryCardWithAreaChart.js

import React from 'react';
import { Paper, Box, Typography, IconButton, Tooltip, Chip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TinyAreaChart from '../Charts/TinyAreaChart';
import { useTheme } from '@mui/material/styles';
import { getColorByPercentage } from '../../utils/colorUtils'; // Ensure correct path

const SummaryCardWithAreaChart = ({
    icon: Icon,
    label,
    value,
    color,
    tooltip,
    percentageChange,
    chartData,
    badge, // New prop for the badge
    sx, // Accept sx prop for custom styling
}) => {
    const theme = useTheme();
    const dispatchPalette = theme.palette.dispatch;

    return (
        <Paper
            elevation={3}
            sx={{
                p: 3,
                borderRadius: 2,
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                position: 'relative',
                ...sx,
            }}
        >
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: { xs: 40, sm: 56 },
                            width: { xs: 40, sm: 56 },
                            backgroundColor: color,
                            color: '#fff',
                            borderRadius: '50%',
                            mr: 2,
                        }}
                    >
                        <Icon fontSize="large" />
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                                {label}
                            </Typography>
                            {badge && (
                                <Chip
                                    label={badge}
                                    color="primary"
                                    size="small"
                                    sx={{ ml: 1 }}
                                    aria-label={`${badge} badge`}
                                />
                            )}
                            {tooltip && (
                                <Tooltip title={tooltip} arrow>
                                    <IconButton size="small" sx={{ ml: 0.5 }}>
                                        <InfoOutlinedIcon fontSize="small" color="action" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 'bold',
                                color: theme.palette.text.primary,
                                transition: 'color 0.3s ease',
                            }}
                        >
                            {value}
                        </Typography>
                    </Box>
                </Box>

                {/* Percentage Change */}
                {percentageChange !== undefined && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            variant="body2"
                            sx={{
                                color: percentageChange >= 0 ? theme.palette.success.main : theme.palette.error.main,
                                fontWeight: 'bold',
                                fontSize: { xs: '0.75rem', sm: '0.875rem' }, // 12px or 14px
                            }}
                        >
                            {percentageChange >= 0 ? '↑' : '↓'} {Math.abs(percentageChange).toFixed(2)}%
                        </Typography>
                    </Box>
                )}
            </Box>

            {/* "Last 7 Days" Label */}
            <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
                Last 7 days
            </Typography>

            {/* TinyAreaChart */}
            <Box sx={{ height: { xs: '80px', sm: '120px' }, mt: 1 }}>
                <TinyAreaChart data={chartData} color={color}/>
            </Box>
        </Paper>
    );

};

export default SummaryCardWithAreaChart;
