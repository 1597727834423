// frontend/src/components/UserManagement.js

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    Grid,
    Typography,
    IconButton,
    Tooltip,
    Box,
    Paper,
    Toolbar,
} from '@mui/material';
import { Delete, Edit, Add, Key, FilterList as FilterListIcon } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import Layout from './Layout';
import { getUsers, addUser, editUser, deleteUser, resetUserPassword } from '../services/api';
import { validatePassword } from '../utils/validation';
import { useSnackbar } from '../contexts/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import CustomTable from './CustomTable'; // Import the CustomTable component

const UserManagement = () => {
    const { showSnackbar } = useSnackbar();
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const loadUsers = async () => {
        try {
            const response = await getUsers();
            setUsers(response && response.users ? response.users : []);
        } catch (error) {
            if (error.status === 403) {
                showSnackbar('You do not have permission to access this resource', 'error');
                navigate('/access-denied');
            } else {
                showSnackbar('Error fetching users.', 'error');
            }
        }
    };

    useEffect(() => {
        loadUsers();
    }, []);

    const [newUser, setNewUser] = useState({
        username: '',
        password: '',
        RoleId: '2',
        firstName: '',
        lastName: '',
        email: '',
        countryCode: '',
        phoneNumber: '',
        jobTitle: '',
        department: '',
    });
    const [editUserModal, setEditUserModal] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('username');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [dense] = useState(true);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedUsers = users.slice().sort((a, b) => {
        if (orderBy === 'username') {
            return order === 'asc' ? a.Username.localeCompare(b.Username) : b.Username.localeCompare(a.Username);
        } else if (orderBy === 'firstName') {
            return order === 'asc' ? a.FirstName.localeCompare(b.FirstName) : b.FirstName.localeCompare(a.FirstName);
        } else if (orderBy === 'lastName') {
            return order === 'asc' ? a.LastName.localeCompare(b.LastName) : b.LastName.localeCompare(a.LastName);
        } else if (orderBy === 'email') {
            return order === 'asc' ? a.Email.localeCompare(b.Email) : b.Email.localeCompare(a.Email);
        } else if (orderBy === 'RoleId') {
            return order === 'asc' ? a.RoleId - b.RoleId : b.RoleId - a.RoleId;
        }
        return 0;
    });

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users.map((user) => user.Id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleAddUser = async () => {
        if (!validatePassword(newUser.password)) {
            showSnackbar('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.', 'error');
            return;
        }

        try {
            await addUser(newUser);
            showSnackbar(`User ${newUser.username} has been created successfully.`, 'success');
            setShowAddModal(false);
            setNewUser({
                username: '',
                password: '',
                RoleId: '2',
                firstName: '',
                lastName: '',
                email: '',
                countryCode: '',
                phoneNumber: '',
                jobTitle: '',
                department: '',
            });
            loadUsers();
        } catch (error) {
            showSnackbar('Error adding user', 'error');
        }
    };

    const handleEditUser = async () => {
        if (editUserModal.password && !validatePassword(editUserModal.password)) {
            showSnackbar('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.', 'error');
            return;
        }

        try {
            await editUser(editUserModal.Id, {
                username: editUserModal.Username,
                RoleId: editUserModal.RoleId,
                firstName: editUserModal.FirstName,
                lastName: editUserModal.LastName,
                email: editUserModal.Email,
                countryCode: editUserModal.CountryCode,
                phoneNumber: editUserModal.PhoneNumber,
                jobTitle: editUserModal.JobTitle,
                department: editUserModal.Department,
            });
            showSnackbar('User has been updated successfully.', 'success');
            setEditUserModal(null);
            loadUsers();
        } catch (error) {
            showSnackbar('Error editing user', 'error');
        }
    };

    const handleDeleteUser = async () => {
        try {
            await deleteUser(selected);
            showSnackbar('Selected user(s) have been deleted successfully.', 'success');
            setSelected([]);
            setShowDeleteModal(false);
            loadUsers();
        } catch (error) {
            showSnackbar('Error deleting user', 'error');
        }
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        if (!validatePassword(password)) {
            setPasswordError('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.');
        } else {
            setPasswordError('');
        }
        setNewUser({ ...newUser, password });
    };

    const handleResetPasswordChange = (e) => {
        const password = e.target.value;
        if (!validatePassword(password)) {
            setResetPasswordError('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.');
        } else {
            setResetPasswordError('');
        }
        setResetPasswordModal({ ...resetPasswordModal, newPassword: password });
    };

    const handleResetPassword = async () => {
        if (!validatePassword(resetPasswordModal.newPassword)) {
            showSnackbar('Password must be at least 8 characters long and include both uppercase, lowercase letters, and numbers.', 'error');
            return;
        }

        try {
            await resetUserPassword(resetPasswordModal.Id, resetPasswordModal.newPassword);
            showSnackbar('Password has been reset successfully.', 'success');
            setResetPasswordModal(null);
            loadUsers();
        } catch (error) {
            showSnackbar('Error resetting password', 'error');
        }
    };

    // Define columns for CustomTable
    const columns = [
        { id: 'username', label: 'Username', minWidth: 150, align: 'left', sortable: true },
        { id: 'firstName', label: 'First Name', minWidth: 150, align: 'left', sortable: true },
        { id: 'lastName', label: 'Last Name', minWidth: 150, align: 'left', sortable: true },
        { id: 'email', label: 'Email', minWidth: 200, align: 'left', sortable: true },
        { id: 'RoleId', label: 'Role', minWidth: 100, align: 'left', sortable: true },
        { id: 'actions', label: 'Actions', minWidth: 150, align: 'left', sortable: false },
    ];

    // Prepare data for CustomTable
    const tableData = sortedUsers.map(user => ({
        username: user.Username,
        firstName: user.FirstName,
        lastName: user.LastName,
        email: user.Email,
        RoleId: user.RoleId === 1 ? 'Admin' : 'User',
        actions: (
            <>
                <Tooltip title="Edit User">
                    <IconButton
                        color="primary"
                        onClick={() => setEditUserModal(user)}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Reset Password">
                    <IconButton
                        color="warning"
                        onClick={() =>
                            setResetPasswordModal({ Id: user.Id, newPassword: '' })
                        }
                    >
                        <Key />
                    </IconButton>
                </Tooltip>
            </>
        ),
        Id: user.Id, // Assuming 'Id' is unique for selection purposes
    }));

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    User Management
                </Typography>

                {/* Add User Button Wrapped in Paper */}
                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={() => setShowAddModal(true)}
                        fullWidth
                    >
                        Add User
                    </Button>
                </Paper>

                {/* Users Table Wrapped in Paper and CustomTable */}
                <Paper elevation={3} sx={{ p: 2 }}>
                    <Toolbar
                        sx={[
                            {
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                            },
                            selected.length > 0 && {
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            },
                        ]}
                    >
                        {selected.length > 0 ? (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                {selected.length} selected
                            </Typography>
                        ) : (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                                Users
                            </Typography>
                        )}

                        {selected.length > 0 ? (
                            <Tooltip title="Delete">
                                <IconButton onClick={() => setShowDeleteModal(true)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Filter list">
                                <IconButton>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Toolbar>

                    {/* CustomTable Component */}
                    <CustomTable
                        columns={columns}
                        data={tableData}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        // Selection handlers
                        onRowClick={(id) => handleClick(null, id)}
                        selectedRows={selected}
                        selectable
                        selectAllHandler={handleSelectAllClick}
                        rowId="Id"
                    />
                </Paper>

                {/* Add User Modal */}
                <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="md">
                    <DialogTitle>Add New User</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Username"
                                    fullWidth
                                    value={newUser.username}
                                    onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    value={newUser.password}
                                    onChange={handlePasswordChange}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    label="Role"
                                    fullWidth
                                    value={newUser.RoleId}
                                    onChange={(e) => setNewUser({ ...newUser, RoleId: e.target.value })}
                                    required
                                >
                                    <MenuItem value="1">Admin</MenuItem>
                                    <MenuItem value="2">User</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="First Name"
                                    fullWidth
                                    value={newUser.firstName}
                                    onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Last Name"
                                    fullWidth
                                    value={newUser.lastName}
                                    onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    value={newUser.email}
                                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Country Code"
                                    fullWidth
                                    value={newUser.countryCode}
                                    onChange={(e) => setNewUser({ ...newUser, countryCode: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Phone Number"
                                    fullWidth
                                    value={newUser.phoneNumber}
                                    onChange={(e) => setNewUser({ ...newUser, phoneNumber: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Job Title"
                                    fullWidth
                                    value={newUser.jobTitle}
                                    onChange={(e) => setNewUser({ ...newUser, jobTitle: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Department"
                                    fullWidth
                                    value={newUser.department}
                                    onChange={(e) => setNewUser({ ...newUser, department: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowAddModal(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleAddUser} color="primary">
                            Save User
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Edit User Modal */}
                {editUserModal && (
                    <Dialog open={Boolean(editUserModal)} onClose={() => setEditUserModal(null)} fullWidth maxWidth="md">
                        <DialogTitle>Edit User</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Username"
                                        fullWidth
                                        value={editUserModal.Username || ''}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        select
                                        label="Role"
                                        fullWidth
                                        value={editUserModal.RoleId}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, RoleId: e.target.value })}
                                        required
                                    >
                                        <MenuItem value="1">Admin</MenuItem>
                                        <MenuItem value="2">User</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="First Name"
                                        fullWidth
                                        value={editUserModal.FirstName || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, FirstName: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Last Name"
                                        fullWidth
                                        value={editUserModal.LastName || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, LastName: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Email"
                                        type="email"
                                        fullWidth
                                        value={editUserModal.Email || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, Email: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Country Code"
                                        fullWidth
                                        value={editUserModal.CountryCode || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, CountryCode: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Phone Number"
                                        fullWidth
                                        value={editUserModal.PhoneNumber || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, PhoneNumber: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Job Title"
                                        fullWidth
                                        value={editUserModal.JobTitle || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, JobTitle: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Department"
                                        fullWidth
                                        value={editUserModal.Department || ''}
                                        onChange={(e) => setEditUserModal({ ...editUserModal, Department: e.target.value })}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setEditUserModal(null)} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleEditUser} color="primary">
                                Save Changes
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {/* Delete Confirmation Modal */}
                <Dialog open={showDeleteModal} onClose={() => setShowDeleteModal(false)} fullWidth maxWidth="xs">
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Do you really want to delete the selected user(s)? This process cannot be undone.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowDeleteModal(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteUser} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Reset Password Modal */}
                {resetPasswordModal && (
                    <Dialog open={Boolean(resetPasswordModal)} onClose={() => setResetPasswordModal(null)} fullWidth maxWidth="sm">
                        <DialogTitle>Reset Password</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="New Password"
                                type="password"
                                fullWidth
                                value={resetPasswordModal.newPassword || ''}
                                onChange={handleResetPasswordChange}
                                error={!!resetPasswordError}
                                helperText={resetPasswordError}
                                required
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setResetPasswordModal(null)} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleResetPassword} color="primary">
                                Reset Password
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {/* Add User Modal */}
                <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="md">
                    <DialogTitle>Add New User</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Username"
                                    fullWidth
                                    value={newUser.username}
                                    onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    value={newUser.password}
                                    onChange={handlePasswordChange}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    label="Role"
                                    fullWidth
                                    value={newUser.RoleId}
                                    onChange={(e) => setNewUser({ ...newUser, RoleId: e.target.value })}
                                    required
                                >
                                    <MenuItem value="1">Admin</MenuItem>
                                    <MenuItem value="2">User</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="First Name"
                                    fullWidth
                                    value={newUser.firstName}
                                    onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Last Name"
                                    fullWidth
                                    value={newUser.lastName}
                                    onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    value={newUser.email}
                                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Country Code"
                                    fullWidth
                                    value={newUser.countryCode}
                                    onChange={(e) => setNewUser({ ...newUser, countryCode: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Phone Number"
                                    fullWidth
                                    value={newUser.phoneNumber}
                                    onChange={(e) => setNewUser({ ...newUser, phoneNumber: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Job Title"
                                    fullWidth
                                    value={newUser.jobTitle}
                                    onChange={(e) => setNewUser({ ...newUser, jobTitle: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Department"
                                    fullWidth
                                    value={newUser.department}
                                    onChange={(e) => setNewUser({ ...newUser, department: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowAddModal(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleAddUser} color="primary">
                            Save User
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Layout>
    );
};

export default UserManagement;
