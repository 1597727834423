// frontend/src/components/CustomTable.js

import React, { useState, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    Checkbox,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const CustomTable = ({
    columns,
    data,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    selectable = false,
    selectedRows = [],
    onRowClick,
    selectAllHandler,
    rowId = 'id',
    total = data.length,
}) => {
    const [order, setOrder] = useState('asc');
    const [orderByState, setOrderByState] = useState('');

    const handleRequestSort = (property) => {
        const isAsc = orderByState === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderByState(property);
    };

    const sortedData = useMemo(() => {
        if (!orderByState) return data;

        return [...data].sort((a, b) => {
            const aValue = a[orderByState] || '';
            const bValue = b[orderByState] || '';

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return order === 'asc' ? aValue - bValue : bValue - aValue;
            }

            return order === 'asc'
                ? aValue.toString().localeCompare(bValue.toString())
                : bValue.toString().localeCompare(aValue.toString());
        });
    }, [data, order, orderByState]);

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="custom table">
                    <TableHead>
                        <TableRow>
                            {selectable && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
                                        checked={data.length > 0 && selectedRows.length === data.length}
                                        onChange={selectAllHandler}
                                        inputProps={{ 'aria-label': 'select all rows' }}
                                    />
                                </TableCell>
                            )}
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align || 'left'}
                                    style={{ minWidth: column.minWidth }}
                                    sortDirection={orderByState === column.id ? order : false}
                                >
                                    {column.sortable ? (
                                        <TableSortLabel
                                            active={orderByState === column.id}
                                            direction={orderByState === column.id ? order : 'asc'}
                                            onClick={() => handleRequestSort(column.id)}
                                        >
                                            {column.label}
                                            {orderByState === column.id ? (
                                                <span style={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    ) : (
                                        column.label
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row) => {
                            const isItemSelected = isSelected(row[rowId]);

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row[rowId]}
                                    selected={isItemSelected}
                                    onClick={() => onRowClick && onRowClick(row)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {selectable && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onChange={(event) => {
                                                    event.stopPropagation();
                                                    onRowClick && onRowClick(row[rowId]);
                                                }}
                                                inputProps={{
                                                    'aria-labelledby': `enhanced-table-checkbox-${row[rowId]}`,
                                                }}
                                            />
                                        </TableCell>
                                    )}
                                    {columns.map((column) => (
                                        <TableCell key={column.id} align={column.align || 'left'}>
                                            {typeof row[column.id] === 'function'
                                                ? row[column.id]()
                                                : row[column.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, 250]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );

};

export default CustomTable;
