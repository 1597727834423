// frontend/src/components/CustomerDetailModal.js

import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Grid,
    IconButton,
    Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
};

const CustomerDetailModal = ({ customer, open, onClose }) => {
    if (!customer) return null;

    const customerDetails = [
        { label: 'Customer ID', value: customer.CustomerID },
        { label: 'Customer Code', value: customer.Customer_Code },
        { label: 'Customer Name', value: customer.Customer_Name },
        { label: 'Address', value: customer.Customer_Address },
        { label: 'Town', value: customer.Customer_Town },
        { label: 'County', value: customer.Customer_County },
        { label: 'Country', value: customer.Customer_Country },
        { label: 'Postcode', value: customer.Customer_Postcode },
        { label: 'Default Contact', value: customer.Customer_DefaultContact },
        { label: 'Telephone', value: customer.Customer_TelNo },
        { label: 'Fax Number', value: customer.Customer_FaxNo },
        { label: 'Website', value: customer.Customer_WebsiteAddress },
        { label: 'Default Markup %', value: customer.Customer_DefaultMarkupPercentage },
        { label: 'Carriage Band', value: customer.Customer_CarriageBand },
        { label: 'Currency', value: customer.Customer_Currency },
        { label: 'Commission %', value: customer.Customer_CommissionPercentage },
        { label: 'VAT Registration', value: customer.Customer_VatRegistration ? 'Yes' : 'No' },
        { label: 'Payment Terms', value: customer.Customer_PaymentTerms },
        { label: 'Default Courier', value: customer.Customer_DefaultCourier },
        { label: 'Company Name', value: customer.Customer_CompanyName },
        { label: 'Status', value: customer.Customer_Status ? <Chip label={customer.Customer_Status} color="success" /> : 'N/A' },
        // Add more fields as necessary
    ];

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="customer-detail-modal-title"
            aria-describedby="customer-detail-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography id="customer-detail-modal-title" variant="h6" component="h2">
                        Customer Details
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={2}>
                    {customerDetails.map((detail, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    {detail.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1">
                                    {detail.value}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                {/* Add more sections as needed */}
            </Box>
        </Modal>
    );
};

export default CustomerDetailModal;
