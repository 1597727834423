// frontend/src/components/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiLogin } from '../services/api';
import { AuthContext } from '../contexts/AuthContext'; // Import AuthContext
import {
    Box,
    Card,
    CardContent,
    TextField,
    Button,
    Typography,
    Grid,
    Alert
} from '@mui/material';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false); // To control alert visibility
    const navigate = useNavigate();
    const { login } = useContext(AuthContext); // Use login from AuthContext

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the form from refreshing the page
        try {
            const response = await apiLogin({ username, password });
            if (response.success) {
                login(); // Update authentication state
                navigate('/dashboard'); // Redirect to dashboard
            } else {
                setErrorMessage(response.message); // Show the correct error message from the backend
                setShowError(true); // Show error alert
                hideErrorAfterDelay(); // Hide after 5 seconds
            }
        } catch (error) {
            console.error('Login error:', error);
            setErrorMessage('An error occurred during login. Please try again.');
            setShowError(true); // Show error alert
            hideErrorAfterDelay(); // Hide after 5 seconds
        }
    };

    // Function to hide the error after 5 seconds
    const hideErrorAfterDelay = () => {
        setTimeout(() => {
            setShowError(false); // Hide error message after 5 seconds
        }, 5000);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f5f5f5' // Light grey background
            }}
        >
            <Card elevation={3} sx={{ maxWidth: 400, width: '100%', padding: 3 }}>
                <CardContent>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} textAlign="center">
                            <img
                                src="/images/LogoPrimaryStacked.png"
                                alt="Logo"
                                style={{ width: '150px', marginBottom: '16px' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center" gutterBottom>
                                Internal Fulfilment System
                            </Typography>
                            <Typography variant="h6" align="center" gutterBottom>
                                Login
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Show alert only when there's an error and it's set to visible */}
                    {showError && (
                        <Alert severity="error" sx={{ marginBottom: 2 }}>
                            {errorMessage}
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{ marginTop: 2, padding: '10px 0' }}
                        >
                            Login
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Login;
