// frontend/src/components/OverallSummary.js

import React from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Paper,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import CustomTable from './CustomTable'; // Import the CustomTable component

const OverallSummary = ({ summaryData, granularity, chartData }) => {
    // Define table columns
    const columns = [
        {
            id: 'Period',
            label: 'Period',
            minWidth: 150,
            align: 'left',
            sortable: true,
        },
        {
            id: 'ReceivedOrders',
            label: 'Received Orders',
            minWidth: 150,
            align: 'right',
            sortable: true,
        },
        {
            id: 'DispatchedOrders',
            label: 'Dispatched Orders',
            minWidth: 150,
            align: 'right',
            sortable: true,
        },
        {
            id: 'CancelledOrders',
            label: 'Cancelled Orders',
            minWidth: 150,
            align: 'right',
            sortable: true,
        },
    ];

    // State for pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
            {/* Overall Summary Content Wrapped in Paper */}
            <Paper elevation={3} sx={{ p: 3, mb: 5 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                    Orders Overview
                </Typography>
                {chartData.length > 0 ? (
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            data={chartData}
                            margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {/* Stacked Bars */}
                            <Bar dataKey="DispatchedOrders" stackId="a" fill="#20c997" />
                            <Bar dataKey="CancelledOrders" stackId="a" fill="#dc3545" />
                            <Bar dataKey="RemainingInProduction" stackId="a" fill="#197DC2" />
                        </BarChart>
                    </ResponsiveContainer>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        No data available to display the chart.
                    </Typography>
                )}
            </Paper>

            {/* Overall Summary Table Section Wrapped in Paper */}
            <Paper elevation={3} sx={{ p: 3, mb: 5 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                    Overall Summary
                </Typography>
                {summaryData.length > 0 ? (
                    <CustomTable
                        columns={columns}
                        data={summaryData.map(row => ({
                            Period: granularity === 'Day'
                                ? moment(row.date).format('YYYY-MM-DD')
                                : granularity === 'Week'
                                    ? `Week Starting ${moment(row.weekStartDate).format('YYYY-MM-DD')}`
                                    : moment({ year: row.Year, month: row.month - 1 }).format('YYYY MMMM'),
                            ReceivedOrders: row.receivedOrders,
                            DispatchedOrders: row.dispatchedOrders,
                            CancelledOrders: row.cancelledOrders,
                        }))}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        No summary data available for the selected criteria.
                    </Typography>
                )}
            </Paper>
        </Box>
    );
};

export default OverallSummary;
