// frontend/src/components/CourierDispatchReport.js

import React, { useEffect, useState, useMemo } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Paper,
    Grid,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { getCourierDispatchSummary } from '../services/api';
import { useSnackbar } from '../contexts/SnackbarContext';
import moment from 'moment';
import CustomTable from './CustomTable'; // Import the CustomTable component

const colorPalette = [
    '#197DC2', // Evri
    '#20c997', // Parcelforce
    '#dc3545', // Royal Mail
    '#ffc107', // DHL
    '#6f42c1', // DPD
    '#007bff', // Fedex
    '#28a745', // UPS
    // Add more colors as needed
];

const CourierDispatchReport = ({ granularity, startDate, endDate }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [dispatchData, setDispatchData] = useState([]);
    const [aggregatedData, setAggregatedData] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showSnackbar } = useSnackbar();

    const fetchDispatchData = async () => {
        setLoading(true);
        try {
            const data = await getCourierDispatchSummary(granularity, startDate, endDate);
            setDispatchData(data);
            showSnackbar('Courier dispatch data fetched successfully.', 'success');
        } catch (error) {
            showSnackbar('Failed to fetch courier dispatch data.', 'error');
            console.error('Fetch Dispatch Data Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDispatchData();
    }, [granularity, startDate, endDate]);

    useEffect(() => {
        if (dispatchData.length === 0) {
            setAggregatedData([]);
            setCouriers([]);
            return;
        }

        // Extract unique couriers
        const uniqueCouriers = [...new Set(dispatchData.map(item => item.Courier))].sort();
        setCouriers(uniqueCouriers);

        // Aggregate data by Period
        const aggregationMap = {};

        dispatchData.forEach(item => {
            const period = granularity === 'Month'
                ? moment({ year: item.Year, month: item.Period - 1 }).format('YYYY MMM')
                : granularity === 'Week'
                    ? `Week Starting ${moment(item.Period).format('YYYY-MM-DD')}`
                    : moment(item.Period).format('YYYY-MM-DD');

            if (!aggregationMap[period]) {
                aggregationMap[period] = { Period: period };
                uniqueCouriers.forEach(courier => {
                    aggregationMap[period][courier] = 0;
                });
            }

            aggregationMap[period][item.Courier] += item.OrdersSent;
        });

        const aggregatedArray = Object.values(aggregationMap).sort((a, b) => {
            if (granularity === 'Day' || granularity === 'Week') {
                return moment(a.Period, granularity === 'Week' ? 'Week Starting YYYY-MM-DD' : 'YYYY-MM-DD').diff(moment(b.Period, granularity === 'Week' ? 'Week Starting YYYY-MM-DD' : 'YYYY-MM-DD'));
            } else { // Month
                return moment(a.Period, 'YYYY MMM').diff(moment(b.Period, 'YYYY MMM'));
            }
        });

        setAggregatedData(aggregatedArray);
    }, [dispatchData, granularity]);

    // Define table columns
    const columns = [
        {
            id: 'Period',
            label: 'Period',
            minWidth: 150,
            align: 'left',
            sortable: true,
        },
        ...couriers.map((courier, index) => ({
            id: courier,
            label: courier,
            minWidth: 120,
            align: 'right',
            sortable: false,
        })),
    ];

    // State for pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Assign colors to couriers
    const courierColors = useMemo(() => {
        return couriers.reduce((acc, courier, index) => {
            acc[courier] = colorPalette[index % colorPalette.length];
            return acc;
        }, {});
    }, [couriers]);

    return (
        <Box sx={{ mb: 5 }}>
            {/* Courier Dispatch Report Wrapped in Paper */}
            <Paper elevation={3} sx={{ p: 3, mb: 5 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
                    Courier Dispatch Report
                </Typography>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {/* Stacked Bar Chart Wrapped in Paper */}
                        <Paper elevation={1} sx={{ p: 2, mb: 3 }}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Orders Sent per Courier
                            </Typography>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={aggregatedData}
                                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="Period" />
                                    <YAxis allowDecimals={false} />
                                    <Tooltip />
                                    <Legend />
                                    {couriers.map((courier, index) => (
                                        <Bar
                                            key={courier}
                                            dataKey={courier}
                                            stackId="a"
                                            fill={courierColors[courier]}
                                        />
                                    ))}
                                </BarChart>
                            </ResponsiveContainer>
                        </Paper>

                        {/* Aggregated Table Wrapped in CustomTable */}
                        <Paper elevation={1} sx={{ p: 2 }}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Courier Dispatch Summary Table
                            </Typography>
                            <CustomTable
                                columns={columns}
                                data={aggregatedData.map(row => ({
                                    Period: row.Period,
                                    ...couriers.reduce((acc, courier) => {
                                        acc[courier] = row[courier] || 0;
                                        return acc;
                                    }, {}),
                                }))}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </>
                )}
            </Paper>
        </Box>
    )
};

export default CourierDispatchReport;
